import { BigNumber, ethers } from "ethers";
import Web3Modal from "web3modal";
import { Subject, BehaviorSubject } from 'rxjs'
import { CONTRACT_ADDRESS, CONTRACT_ABI, ERC721_ABI } from ".././config"
import { MintInfo } from "./MintInfo";
import { ChainModel } from "./ChainModel";

import WalletConnectProvider from "@walletconnect/web3-provider";
import { RoyaltiesInfo } from "./RoyaltiesInfo";
import { RAFFLE_ABI, RAFFLE_ADDRESS } from "../config-raffle";
import { RaffleItem } from "./raffleItem";
import { MUTANTS_ABI, MUTANTS_ADDRESS } from "../config-mutants";
import { HOS_PETS_ABI, HOS_PETS_ADDRESS } from "../config-hos-pets";
import { COIN_ABI, STAKING_ABI, STAKING_ADDRESS, WETH_ADDRESS } from "../config-staking";
import { opera } from "web3modal/dist/providers/connectors";
import { KULLY_ABI, KULLY_ADDRESS } from "../config-kully";
import { MetadataNFT } from "./MetadataNFT";
import { SHOP_ABI, SHOP_ADDRESS } from "../config-shop";

export type Data = { raffle: RaffleItem, rewardClaimed: boolean, prizeClaimed: boolean, didClaimCancelled: boolean }
export type StakingInfo = { totalStaked: BigNumber, seasonEndTimestamp: BigNumber, rewardsToken: string, pool: BigNumber }
export type StakeInfo = { tokenIds: BigNumber[], boosterIds: BigNumber[], rewardsClaimed: BigNumber, pendingRewards: BigNumber }
export type ShopItem = { id: BigNumber, status: BigNumber, nftContract: string, tokenId: BigNumber, name: string, price: BigNumber }

export default class Web3Service {
    static instance: Web3Service

    static shared() {
        if (Web3Service.instance) {
            return Web3Service.instance
        } else {
            Web3Service.instance = new Web3Service()
            return Web3Service.instance
        }
    }

    static polygonRpc = "https://polygon-rpc.com";
    static defaultProvider = new ethers.providers.JsonRpcProvider(Web3Service.polygonRpc);

    // Private
    private _kullyBalance$ = new BehaviorSubject<BigNumber | undefined>(undefined)

    private _connected$ = new BehaviorSubject<boolean>(false)
    private _isLoading$ = new BehaviorSubject<boolean>(false)
    private _account$ = new BehaviorSubject<string | undefined>(undefined)
    private _mintInfo$ = new BehaviorSubject<MintInfo | undefined>(undefined)

    private _royalties$ = new BehaviorSubject<RoyaltiesInfo | undefined>(undefined)
    private _mutantsRoyalties$ = new BehaviorSubject<RoyaltiesInfo | undefined>(undefined)

    private _tokens$ = new BehaviorSubject<number[]>([])
    private _mutants$ = new BehaviorSubject<number[]>([])
    private _hosPets$ = new BehaviorSubject<number[]>([])

    private _signature$ = new BehaviorSubject<string | undefined>(undefined)
    private _showToast$ = new Subject<{ title: string }>()
    private _errors$ = new Subject<string>()
    private _isWhitelisted$ = new BehaviorSubject<boolean>(false)
    private _freeMintCount$ = new BehaviorSubject<number>(0)

    private _lastRaffles$ = new Subject<{ lastRaffles: RaffleItem[], playerRaffles: RaffleItem[] }>()
    private _raffleData$ = new Subject<Data | undefined>()
    private _verifiedProjects$ = new BehaviorSubject<string[]>([])

    private _stakingInfo$ = new Subject<StakingInfo | undefined>()
    private _stakeInfo$ = new Subject<StakeInfo | undefined>()
    private _rewards$ = new Subject<string | undefined>()

    private _isApprovedForAll$ = new Subject<boolean>()
    private _isBoosterApproved$ = new Subject<boolean>()

    private _shopActiveItems$ = new Subject<ShopItem[]>()
    private _shopSoldItems$ = new Subject<ShopItem[]>()
    private _isShopManager$ = new Subject<boolean>()

    // Public
    public readonly kullyBalance$ = this._kullyBalance$.asObservable()

    public readonly connected$ = this._connected$.asObservable()
    public readonly account$ = this._account$.asObservable()
    public readonly mintInfo$ = this._mintInfo$.asObservable()

    public readonly royalties$ = this._royalties$.asObservable()
    public readonly mutantsRoyalties$ = this._mutantsRoyalties$.asObservable()

    public readonly tokens$ = this._tokens$.asObservable()
    public readonly mutants$ = this._mutants$.asObservable()
    public readonly hosPets$ = this._hosPets$.asObservable()

    public readonly signature$ = this._signature$.asObservable()
    public readonly showToast$ = this._showToast$.asObservable()
    public readonly errors$ = this._errors$.asObservable()
    public readonly isLoading$ = this._isLoading$.asObservable()
    public readonly isWhitelisted$ = this._isWhitelisted$.asObservable()
    public readonly freeMintCount$ = this._freeMintCount$.asObservable()

    public readonly lastRaffles$ = this._lastRaffles$.asObservable()
    public readonly raffleData$ = this._raffleData$.asObservable()
    public readonly verifiedProjects$ = this._verifiedProjects$.asObservable()

    public readonly stakingInfo$ = this._stakingInfo$.asObservable()
    public readonly stakeInfo$ = this._stakeInfo$.asObservable()
    public readonly rewards$ = this._rewards$.asObservable()

    public readonly isApprovedForAll$ = this._isApprovedForAll$.asObservable()
    public readonly isBoosterApproved$ = this._isBoosterApproved$.asObservable()

    public readonly shopActiveItems$ = this._shopActiveItems$.asObservable()
    public readonly shopSoldItems$ = this._shopSoldItems$.asObservable()
    public readonly isShopManager$ = this._isShopManager$.asObservable()

    // Logic
    private web3Modal: Web3Modal
    private provider?: ethers.providers.Web3Provider
    private didConnectOnLoad: boolean = false
    private connector: any

    private _chain = new ChainModel(
        137,
        "Polygon",
        "Polygon Matic",
        18,
        "MATIC",
        ['https://polygon-rpc.com']
    )

    constructor() {
        const providerOptions = {
            "custom-walletconnect": {
                display: {
                    logo: "https://docs.walletconnect.com/img/walletconnect-logo.svg",
                    name: "WalletConnect",
                    description: "Connect with any WalletConnect compatible wallet."
                },
                options: {
                    appName: 'DooNFT Launchpad dApp',
                    networkUrl: 'https://polygon-rpc.com',
                    chainId: 137
                },
                package: WalletConnectProvider,
                connector: async () => {
                    const connector = new WalletConnectProvider({
                        rpc: {
                            137: "https://polygon-rpc.com"
                        },
                        chainId: 137,
                        bridge: 'https://derelay.rabby.io'
                    });
                    await connector.enable();
                    this.connector = connector;
                    return connector;
                }
            },
        }

        this.web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions
        })
    }

    isCorrectChainId = () => {
        if (window.ethereum) return window.ethereum.networkVersion == this._chain.id
        return true
    }

    connectToCachedProvider = async () => {
        if (this.didConnectOnLoad || !this.web3Modal.cachedProvider) return

        this._isLoading$.next(true)
        this.didConnectOnLoad = true
        this.web3Modal.connectTo(this.web3Modal.cachedProvider)
            .then(provider => {
                this.walletConnected(provider)
                this._isLoading$.next(false)
            })
            .catch(error => {
                this._errors$.next("Failed to connect")
                this._isLoading$.next(false)
            })
    }

    toggleConnect = async () => {
        if (this._connected$.value) {
            try {
                this.connector.disconnect();
            }
            catch (error) {
            }
            try {
                this.connector.deactivate();
            }
            catch (error) {
            }

            this.disconnect()
        } else {
            this.connectToWallet()
        }
    }

    switchNetwork = async () => {
        if (this.isCorrectChainId()) return

        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: ethers.utils.hexValue(this._chain.id) }]
            })
        } catch (err: any) {
            if (err.code == 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainName: this._chain.name,
                        chainId: ethers.utils.hexValue(this._chain.id),
                        nativeCurrency: { name: this._chain.currencyName, decimals: this._chain.decimals, symbol: this._chain.symbol },
                        rpcUrls: this._chain.rpcUrls
                    }]
                })
            } else if (err.code == 4901) {
                return
            } else {
                this._errors$.next('There was a problem adding ' + this._chain.name + ' network to MetaMask')
            }
        }
    }

    private walletConnected(provider: any) {
        this.provider = new ethers.providers.Web3Provider(provider)
        this._connected$.next(true)
        this._showToast$.next({ title: "Wallet connected" })
        this.getAccount()

        this.observeWalletChanges()
    }

    private observeWalletChanges() {
        this.removeListeners()

        const provider = new ethers.providers.Web3Provider(window.ethereum, "any")

        provider.on("network", (newNetwork, oldNetwork) => {
            if (oldNetwork) {
                window.location.reload()
            }
        })

        window.ethereum.on("accountsChanged", (accounts: string[]) => {
            if (accounts[0] && accounts[0] != this._account$.value) {
                this.getAccount()
            }
        })
    }

    private removeListeners() {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
        provider.removeAllListeners()
    }

    private connectToWallet = async () => {
        this.web3Modal.clearCachedProvider()

        this._isLoading$.next(true)
        this.web3Modal.connect()
            .then(provider => {
                this._isLoading$.next(false)
                this.didConnectOnLoad = true
                this.walletConnected(provider)

            })
            .catch(error => {
                this._isLoading$.next(false)
                this._errors$.next("Failed to connect")
            })
    }

    private disconnect = async () => {
        this.web3Modal.clearCachedProvider()
        this._connected$.next(false)
        this._account$.next(undefined)
        this._mintInfo$.next(undefined)
    }

    private getAccount = async () => {
        if (!this.provider) return

        this._isLoading$.next(true)
        const signer = this.provider.getSigner();
        const address = (await signer.getAddress()).toLowerCase();
        this._account$.next(address)
        this._isLoading$.next(false)
    }

    // MINTING
    mint = async (amount: number) => {
        if (!this.provider || !this._account$.value) {
            this.connectToWallet()
            return
        }

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(HOS_PETS_ADDRESS, HOS_PETS_ABI, signer)

        this._isLoading$.next(true)
        try {
            const price = await contract.mintPrice(this._account$.value)
            const value = parseInt(ethers.utils.formatEther(price)) * amount

            const gasEstimated = await contract.estimateGas.mint(amount, { value: ethers.utils.parseEther(value.toString()) })
            const gas = Math.ceil(gasEstimated.toNumber() * 1.5)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.mint(amount, { value: ethers.utils.parseEther(value.toString()), gasLimit: gasNumber })
            await tx.wait()

            this._showToast$.next({ title: 'Minted ' + amount + " HoS" })
            this.getMintInfo()
            this.whitelistAmount()
        } catch (e) {
            console.error(e)
            this._errors$.next("Could not process transaction")
        } finally {
            this._isLoading$.next(false)
        }
    }

    whitelistAmount = async () => {
        if (!this._account$.value) {
            return
        }
        const contract = new ethers.Contract(HOS_PETS_ADDRESS, HOS_PETS_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            const count = await contract.whitelistCount(this._account$.value)
            this._freeMintCount$.next(count)
        } catch (err) { console.log(err) }

        try {
            const isWhitelisted = await contract.isWhitelisted(this._account$.value)
            this._isWhitelisted$.next(isWhitelisted)
        } catch (err) { console.log(err) }
    }

    getMintInfo = async () => {
        const contract = new ethers.Contract(HOS_PETS_ADDRESS, HOS_PETS_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            const mintInfo = await contract.getMintInfo()
            this._mintInfo$.next(mintInfo)
        } catch { }
    }

    // GALLERY
    getUserTokens = async (address: string | undefined) => {
        if (!this.provider || !this._account$.value) return

        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, this.provider)

        try {
            const tokens = await contract.tokensOfWallet(address ?? this._account$.value)
            this._tokens$.next(tokens)
        } catch (e) {
            //
        }
    }

    getUserMutants = async (address: string | undefined) => {
        if (!this.provider || !this._account$.value) return

        const contract = new ethers.Contract(MUTANTS_ADDRESS, MUTANTS_ABI, this.provider)

        try {
            const tokens = await contract.tokensOfWallet(address ?? this._account$.value)
            this._mutants$.next(tokens)
        } catch (e) {
            //
        }
    }

    getUserHosPets = async (address: string | undefined) => {
        if (!this.provider || !this._account$.value) return

        const contract = new ethers.Contract(HOS_PETS_ADDRESS, HOS_PETS_ABI, this.provider)

        try {
            const tokens = await contract.tokensOfWallet(address ?? this._account$.value)
            this._hosPets$.next(tokens)
        } catch (e) {
            //
        }
    }

    // ROYALTIES
    getRoyalties = async () => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer)

        try {
            const availableToClaim = Number(ethers.utils.formatEther(await contract.getRoyalties(this._account$.value))).toFixed(2)
            const totalRoyalties = Number(ethers.utils.formatEther(await contract.totalRoyalties()))
            this._royalties$.next({ totalRoyalties, availableToClaim })
        } catch (e) {
            //
        }
    }

    claimAllRoyalties = async () => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer)

        try {
            const tx = await contract.claimAllRoyalties()
            await tx.wait()

            this._showToast$.next({ title: "Royalties have been claimed" })
        } catch (e) {
            this._errors$.next("Could not claim royalties")
        }
    }

    // MUTANTS ROYALTIES
    getMutantsRoyalties = async () => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(MUTANTS_ADDRESS, MUTANTS_ABI, signer)

        try {
            const availableToClaim = Number(ethers.utils.formatEther(await contract.getRoyalties(this._account$.value))).toFixed(2)
            const totalRoyalties = Number(ethers.utils.formatEther(await contract.totalRoyalties()))
            this._mutantsRoyalties$.next({ totalRoyalties, availableToClaim })
        } catch (e) {
            //
        }
    }

    claimAllMutantsRoyalties = async () => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(MUTANTS_ADDRESS, MUTANTS_ABI, signer)

        try {
            const tx = await contract.claimAllRoyalties()
            await tx.wait()

            this._showToast$.next({ title: "Royalties have been claimed" })
        } catch (e) {
            this._errors$.next("Could not claim royalties")
        }
    }

    // RAFFLE
    getLastRaffles = async () => {
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            this.getVerifiedProjects()

            const lastRaffles = await contract.getLastRaffles(50)
            let playerRaffles: RaffleItem[] = []
            if (this._account$.value) {
                playerRaffles = await contract.getJoinedRaffles(this._account$.value, 50)
            }

            this._lastRaffles$.next({ lastRaffles, playerRaffles })
        } catch (e) { console.log(e) }
    }

    getRaffleData = async (id: number) => {
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            this.getVerifiedProjects()

            const raffle = await contract.getRaffle(id)
            const claimedRewards = await contract.claimedRewards(id)
            const claimedPrizes = await contract.claimedPrizes(id)
            const cancelledRafflesClaimed = await contract.cancelledRafflesClaimed(this._account$.value ?? ethers.constants.AddressZero, id)

            this._raffleData$.next({ raffle: raffle, rewardClaimed: claimedRewards, prizeClaimed: claimedPrizes, didClaimCancelled: cancelledRafflesClaimed })
        } catch (e) { console.log(e) }
    }

    addRaffle = async (address: string, token: number, name: string, desc: string, tickets: number, price: string, duration: number) => {
        if (!this.provider) return
        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, signer)

        try {
            const isApproved = await this.isApproved(address, token)

            if (!isApproved) {
                await this.approveToken(address, token)
            }

            const cost = await contract.createCost(this._account$.value)

            const gasEstimated = await contract.estimateGas.addRaffle(address, token, name, desc, tickets, ethers.utils.parseEther(price), Math.ceil(duration), { value: cost })
            const gas = Math.ceil(gasEstimated.toNumber() * 2)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.addRaffle(address, token, name, desc, tickets, ethers.utils.parseEther(price), Math.ceil(duration), { value: cost, gasLimit: gasNumber })
            await tx.wait()

            this.getLastRaffles()
            this._showToast$.next({ title: "Raffle added" })
        } catch (e) { console.log(e) }
    }

    approveToken = async (nftContract: string, token: number) => {
        if (!this.provider || !this._account$.value) return
        const contract = new ethers.Contract(nftContract, CONTRACT_ABI, this.provider.getSigner())

        this._isLoading$.next(true)

        try {
            const tx = await contract.approve(RAFFLE_ADDRESS, token)
            await tx.wait()
        } catch (e) {
            this._errors$.next("Failed to approve")
        } finally {
            this._isLoading$.next(false)
        }
    }

    isApproved = async (nftContract: string, token: number): Promise<boolean> => {
        if (!this.provider || !this._account$.value) return false
        const contract = new ethers.Contract(nftContract, CONTRACT_ABI, this.provider)

        try {
            const _address = await contract.getApproved(this._account$.value, RAFFLE_ADDRESS)
            return _address.toLowerCase() == RAFFLE_ADDRESS.toLowerCase()
        } catch (e) {
            return false
        }
    }

    joinRaffle = async (id: number, count: number, ticketPrice: BigNumber) => {
        if (!this.provider) return
        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, signer)

        try {
            const value = Number(ethers.utils.formatEther(ticketPrice)) * count

            const gasEstimated = await contract.estimateGas.joinMultiRaffle(id, count, { value: ethers.utils.parseEther(String(value)) })
            const gas = Math.ceil(gasEstimated.toNumber() * 2)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.joinMultiRaffle(id, count, { value: ethers.utils.parseEther(String(value)), gasLimit: gasNumber })
            await tx.wait()

            this._showToast$.next({ title: "Joined Raffle" })
            this.getRaffleData(id)
        } catch (e) {
            console.log(e)
        }
    }

    claimRefund = async (id: number) => {
        if (!this.provider) return
        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, signer)

        try {
            const gasEstimated = await contract.estimateGas.claimRefund(id)
            const gas = Math.ceil(gasEstimated.toNumber() * 2)
            const gasNumber = BigNumber.from(gas)

            const tx = contract.claimRefund(id, { gasLimit: gasNumber })
            await tx.wait()

            this._showToast$.next({ title: "Refund claimed" })
            this.getRaffleData(id)
        } catch { }
    }

    claimReward = async (id: number) => {
        if (!this.provider) return
        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, signer)

        try {
            const gasEstimated = await contract.estimateGas.claimReward(id)
            const gas = Math.ceil(gasEstimated.toNumber() * 2)
            const gasNumber = BigNumber.from(gas)

            const tx = contract.claimReward(id, { gasLimit: gasNumber })
            await tx.wait()

            this._showToast$.next({ title: "Reward claimed" })
            this.getRaffleData(id)
        } catch { }
    }

    claimNFT = async (id: number) => {
        if (!this.provider) return
        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, signer)

        try {
            const gasEstimated = await contract.estimateGas.claimPrize(id)
            const gas = Math.ceil(gasEstimated.toNumber() * 2)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.claimPrize(id, { gasLimit: gasNumber })
            await tx.wait()
            this._showToast$.next({ title: "NFT claimed" })
            this.getRaffleData(id)
        } catch { }
    }

    cancelRaffle = async (id: number) => {
        if (!this.provider) return
        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, signer)

        try {
            const cost = await contract.cancelCost(this._account$.value)

            const gasEstimated = await contract.estimateGas.cancelMyRaffle(id, { value: cost })
            const gas = Math.ceil(gasEstimated.toNumber() * 2)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.cancelMyRaffle(id, { value: cost, gasLimit: gasNumber })
            await tx.wait()
            this._showToast$.next({ title: "Raffle cancelled" })
            this.getRaffleData(id)
        } catch { }
    }

    addManager = async (manager: string) => {
        if (!this.provider) return
        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, signer)

        try {
            const tx = contract.setManager(manager, true)
            await tx.wait()

            this._showToast$.next({ title: "Manager Added" })
        } catch { }
    }

    whitelistProject = async (project: string) => {
        if (!this.provider) return
        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, signer)

        try {
            const tx = contract.setWhitelistProject(project, true)
            await tx.wait()

            this._showToast$.next({ title: "Project whitelisted" })
        } catch { }
    }

    verifyProject = async (project: string) => {
        if (!this.provider) return
        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, signer)

        try {
            const tx = contract.setVerifiedProject(project, true)
            await tx.wait()

            this._showToast$.next({ title: "Project verified" })
        } catch { }
    }

    getVerifiedProjects = async () => {
        const contract = new ethers.Contract(RAFFLE_ADDRESS, RAFFLE_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            const projects = await contract.getAllVerifiedProjects()
            this._verifiedProjects$.next(projects)
        } catch { }
    }


    // STAKING
    approveStakingToken = async (addressOrName: string, contractInterface: ethers.ContractInterface, operator: string, amount: string) => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(addressOrName, contractInterface, signer)

        try {
            const tx = await contract.approve(operator, ethers.utils.parseEther(amount))
            await tx.wait()

            this._showToast$.next({ title: `Approved WETH` })
        } catch (e) {
            this._errors$.next("Failed to aprove WETH")
        }
    }

    startSeason = async (addressOrName: string, contractInterface: ethers.ContractInterface, amount: string) => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(addressOrName, contractInterface, signer)

        try {
            const tx = await contract.startSeason(ethers.utils.parseEther(amount))
            await tx.wait()

            this._showToast$.next({ title: `Added ${ethers.utils.parseEther(amount)}` })
        } catch (e) {
            this._errors$.next("Failed to start season")
        }
    }

    approveForAll = async (collection: string, operator: string) => {
        if (!this.provider || !this._account$.value) return
        const contract = new ethers.Contract(collection, CONTRACT_ABI, this.provider.getSigner())

        this._isLoading$.next(true)

        try {
            const tx = await contract.setApprovalForAll(operator, true)
            await tx.wait()
            await this.isApprovedForAll(collection, operator)
        } catch (e) {
            this._errors$.next("Failed to approve")
        } finally {
            this._isLoading$.next(false)
        }
    }

    approveBoostersForAll = async (collection: string, operator: string) => {
        if (!this.provider || !this._account$.value) return
        const contract = new ethers.Contract(collection, CONTRACT_ABI, this.provider.getSigner())

        this._isLoading$.next(true)

        try {
            const tx = await contract.setApprovalForAll(operator, true)
            await tx.wait()
            await this.isBoosterApproved(collection, operator)
        } catch (e) {
            this._errors$.next("Failed to approve")
        } finally {
            this._isLoading$.next(false)
        }
    }

    isApprovedForAll = async (collection: string, operator: string) => {
        if (!this.provider || !this._account$.value) return

        const erc721 = new ethers.Contract(collection, CONTRACT_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            const isApproved = await erc721.isApprovedForAll(this._account$.value, operator)
            this._isApprovedForAll$.next(isApproved)
        } catch (e) {
            this._isApprovedForAll$.next(false)
        }
    }

    isBoosterApproved = async (collection: string, operator: string) => {
        if (!this.provider || !this._account$.value) return

        const erc721 = new ethers.Contract(collection, CONTRACT_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            const isApproved = await erc721.isApprovedForAll(this._account$.value, operator)
            this._isBoosterApproved$.next(isApproved)
        } catch (e) {
            this._isBoosterApproved$.next(false)
        }
    }

    getStakingInfo = async (addressOrName: string, contractInterface: ethers.ContractInterface) => {
        const contract = new ethers.Contract(addressOrName, contractInterface, this.provider ?? Web3Service.defaultProvider)

        try {
            const info = await contract.getInfo()
            this._stakingInfo$.next(info)
        } catch (e) {
            console.log(e)
        }
    }

    getStakeInfo = async (addressOrName: string, contractInterface: ethers.ContractInterface) => {
        if (!this.provider || !this._account$.value) return
        const contract = new ethers.Contract(addressOrName, contractInterface, this.provider)

        try {
            const info = await contract.getStakeInfo(this._account$.value)
            this._stakeInfo$.next(info)
        } catch (e) {
            console.error(e)
        }
    }

    getRewards = async (addressOrName: string, contractInterface: ethers.ContractInterface) => {
        if (!this.provider || !this._account$.value) return
        const contract = new ethers.Contract(addressOrName, contractInterface, this.provider)

        try {
            const rewards = await contract.rewards(this._account$.value)
            this._rewards$.next(Number(ethers.utils.formatEther(rewards)).toFixed(8))
        } catch (e) {
            console.error(e)
        }
    }

    claimRewards = async (addressOrName: string, contractInterface: ethers.ContractInterface) => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(addressOrName, contractInterface, signer)

        try {
            const gasEstimated = await contract.estimateGas.claimRewards()
            const gas = Math.ceil(gasEstimated.toNumber() * 2)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.claimRewards({ gasLimit: gasNumber })
            await tx.wait()

            await this.getStakeInfo(addressOrName, contractInterface)
            await this.getRewards(addressOrName, contractInterface)

            this._showToast$.next({ title: `Claimed` })
        } catch (e) {
            this._errors$.next("Failed to claim")
        }
    }

    stake = async (addressOrName: string, contractInterface: ethers.ContractInterface, tokens: number[]) => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(addressOrName, contractInterface, signer)

        try {
            const gasEstimated = await contract.estimateGas.stake(tokens)
            const gas = Math.ceil(gasEstimated.toNumber() * 1.5)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.stake(tokens, { gasLimit: gasNumber })
            await tx.wait()

            await this.getStakeInfo(addressOrName, contractInterface)
            await this.getStakingInfo(addressOrName, contractInterface)

            this._showToast$.next({ title: `Staked ${tokens.length} nfts` })
        } catch (e) {
            this._errors$.next("Failed to stake")
        }
    }


    unstake = async (addressOrName: string, contractInterface: ethers.ContractInterface, tokens: number[]) => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(addressOrName, contractInterface, signer)

        try {
            const gasEstimated = await contract.estimateGas.unstake(tokens)
            const gas = Math.ceil(gasEstimated.toNumber() * 1.5)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.unstake(tokens, { gasLimit: gasNumber })
            await tx.wait()

            await this.getStakeInfo(addressOrName, contractInterface)
            await this.getStakingInfo(addressOrName, contractInterface)

            this._showToast$.next({ title: `Unstaked ${tokens.length} nfts` })
        } catch (e) {
            this._errors$.next("Failed to unstake")
        }
    }

    stakeBooster = async (addressOrName: string, contractInterface: ethers.ContractInterface, tokens: number[]) => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(addressOrName, contractInterface, signer)

        try {
            const gasEstimated = await contract.estimateGas.stakeBoosters(tokens)
            const gas = Math.ceil(gasEstimated.toNumber() * 1.5)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.stakeBoosters(tokens, { gasLimit: gasNumber })
            await tx.wait()

            await this.getStakeInfo(addressOrName, contractInterface)
            await this.getStakingInfo(addressOrName, contractInterface)

            this._showToast$.next({ title: `Staked ${tokens.length} nfts` })
        } catch (e) {
            this._errors$.next("Failed to stake")
        }
    }

    unstakeBooster = async (addressOrName: string, contractInterface: ethers.ContractInterface, tokens: number[]) => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(addressOrName, contractInterface, signer)

        try {
            const gasEstimated = await contract.estimateGas.unstakeBoosters(tokens)
            const gas = Math.ceil(gasEstimated.toNumber() * 1.5)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.unstakeBoosters(tokens, { gasLimit: gasNumber })
            await tx.wait()

            await this.getStakeInfo(addressOrName, contractInterface)
            await this.getStakingInfo(addressOrName, contractInterface)

            this._showToast$.next({ title: `Unstaked ${tokens.length} nfts` })
        } catch (e) {
            this._errors$.next("Failed to unstake")
        }
    }

    getKullyBalance = async () => {
        if (!this.provider || !this._account$.value) {
            this._kullyBalance$.next(undefined)
            return
        }

        const coinContract = new ethers.Contract(KULLY_ADDRESS, KULLY_ABI, this.provider)
        try {
            const balance = await coinContract.balanceOf(this._account$.value)
            this._kullyBalance$.next(balance)
        } catch {
            this._kullyBalance$.next(undefined)
        }
    }

    // SHOP
    getImage = async (address: string, tokenId: number): Promise<string | undefined> => {
        const erc721 = new ethers.Contract(address, ERC721_ABI, Web3Service.defaultProvider)
        const tUri = await erc721.tokenURI(tokenId)
        const tokenUri = tUri.replace("ipfs://", "https://nftstorage.link/ipfs/")
        if (!tokenUri) {
            return undefined
        }

        var metadata: MetadataNFT
        try {
            metadata = await (await fetch(tokenUri)).json()
            // metadata = await ethers.utils.fetchJson(tokenUri) as MetadataNFT
        } catch {
            return undefined
        }

        if (metadata.image.startsWith('ipfs://')) {
            return metadata.image.replace("ipfs://", "https://nftstorage.link/ipfs/")
        } else {
            return metadata.image
        }
    }

    getShopActiveItems = async () => {
        const contract = new ethers.Contract(SHOP_ADDRESS, SHOP_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            this._isLoading$.next(true)
            const items: ShopItem[] = await contract.getActiveItems()
            this._shopActiveItems$.next(items)
        } catch (e) {
            console.log(e)
            this._shopActiveItems$.next([])
        } finally {
            this._isLoading$.next(false)
        }
    }

    getShopSoldItems = async () => {
        const contract = new ethers.Contract(SHOP_ADDRESS, SHOP_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            this._isLoading$.next(true)
            const items: ShopItem[] = await contract.getSoldItems()
            this._shopSoldItems$.next(items)
        } catch (e) {
            console.log(e)
            this._shopSoldItems$.next([])
        } finally {
            this._isLoading$.next(false)
        }
    }

    isShopManager = async () => {
        const contract = new ethers.Contract(SHOP_ADDRESS, SHOP_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            if (this._account$.value) {
                const isManager = await contract.isManager(this._account$.value)
                this._isShopManager$.next(isManager)
            } else {
                this._isShopManager$.next(false)
            }
        } catch (e) {
            console.log(e)
            this._isShopManager$.next(false)
        }
    }

    addShopItem = async (
        nftContract: string, tokenId: string, name: string, price: string
    ) => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const itemContract = new ethers.Contract(nftContract, ERC721_ABI, signer)

        try {
            const isApproved = await itemContract.isApprovedForAll(this._account$.value, SHOP_ADDRESS)
            if (!isApproved) {
                try {
                    const tx = await itemContract.setApprovalForAll(SHOP_ADDRESS, true)
                    await tx.wait()
                } catch (e) {
                    console.log(e)
                    // Wish me luck
                }
            }
        } catch {
            // Wish me luck
        }

        const contract = new ethers.Contract(SHOP_ADDRESS, SHOP_ABI, signer)

        try {
            const gasEstimated = await contract.estimateGas.addItem(nftContract, Number(tokenId), name, ethers.utils.parseEther(price))
            const gas = Math.ceil(gasEstimated.toNumber() * 1.5)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.addItem(nftContract, Number(tokenId), name, ethers.utils.parseEther(price), { gasLimit: gasNumber })
            await tx.wait()

            this.getShopActiveItems()

            this._showToast$.next({ title: `Item added` })
        } catch (e) {
            this._errors$.next("Failed to add item")
        }
    }

    buyShopItem = async (
        id: number,
        price: BigNumber
    ) => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const coinContract = new ethers.Contract(KULLY_ADDRESS, COIN_ABI, signer)

        try {
            const allowance: BigNumber = await coinContract.allowance(this._account$.value, SHOP_ADDRESS)
            if (price.gt(allowance)) {
                try {
                    const tx = await coinContract.approve(SHOP_ADDRESS, price)
                    await tx.wait()
                } catch (e) {
                    console.log(e)
                    // Wish me luck
                }
            }
        } catch {
            // Wish me luck
        }

        const contract = new ethers.Contract(SHOP_ADDRESS, SHOP_ABI, signer)

        try {
            const gasEstimated = await contract.estimateGas.buyItem(id)
            const gas = Math.ceil(gasEstimated.toNumber() * 1.5)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.buyItem(id)
            await tx.wait()

            this.getShopActiveItems()

            this._showToast$.next({
                title: `Congratulations!`
            })
        } catch (e) {
            this._errors$.next("Failed to buy item")
        }
    }
}