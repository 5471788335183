import {
  Box,
  Flex,
  Center,
  IconButton,
  HStack,
  Heading,
  StatGroup,
  Stat,
  StatLabel,
  Container,
  AspectRatio,
  Image,
  VStack,
  StatNumber,
  Text,
  Button,
  SimpleGrid,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Divider,
  Wrap,
  WrapItem,
  NumberInput,
  NumberInputField,
  Input,
} from "@chakra-ui/react";
import Web3Service, { ShopItem } from "../core/web3.service";
import { useEffect, useState } from "react";
import { ethers } from "ethers";

import PlaceholderNFT from "../images/placeholder.webp"
import Kully from "../images/kully.gif"

import { merge, tap } from "rxjs";
import { isAddress } from "ethers/lib/utils";

const ShopItemView: React.FC<{ item: ShopItem }> = (props) => {
  const { item } = props
  const [image, setImage] = useState<string | undefined>(undefined);
  const web3Service = Web3Service.shared();

  useEffect(() => {
    let isSubscribed = true
    const fetchImage = async () => {

      const imageUrl = await web3Service.getImage(item.nftContract, Number(item.tokenId))

      if (isSubscribed) {
        setImage(imageUrl);
      }
    }

    fetchImage().catch()

    return () => {
      isSubscribed = false
    }
  }, []);

  return <>
    <Box
      overflow={'hidden'}
      bg={'rgba(23, 25, 35, 0.7)'}
      backdropFilter="auto"
      backdropBlur="4px"
      css={{
        border: '3px solid black',
        borderImageSlice: 1,
        boxShadow: [
          'inset 0 0 50px #000',
          'inset 2px 0 8px #f00',
          'inset -2px 0 8px #000'
        ]
      }}
      filter={'drop-shadow(8px 8px 10px black)'}
      minW={'256px'}
    >
      <Container centerContent>
        <Text w={'100%'} my={2} textAlign={'right'} fontSize={'xs'} opacity={0.5} fontWeight={400} textColor={'purple.300'}>Item #{Number(item.id)}</Text>
        <Image position={'fixed'} borderRadius={'lg'} objectFit={'cover'}
          filter={Number(item.status) == 1 ? 'blur(64px) grayscale(50%)' : 'blur(64px)'}
          width={'256px'}
          height={'256px'}
          src={image ? `https://wsrv.nl/?url=${image}` : PlaceholderNFT}
          fallbackSrc={image ?? PlaceholderNFT}
        />
        <AspectRatio w='256px' maxH={'256px'} ratio={1}>
          <Image borderRadius={'lg'} objectFit={'cover'} css={{
            opacity: Number(item.status) == 1 ? 0.5 : 1
          }}
            src={image ? `https://wsrv.nl/?url=${image}` : PlaceholderNFT}
            fallbackSrc={image ?? PlaceholderNFT}
          />
        </AspectRatio>
        <VStack spacing={0} mt={4}>
          {/* <Text fontSize={'md'} fontWeight={500} textColor={'purple.100'}>{"NFT"} #{Number(item.tokenId)}</Text> */}
          <Text fontSize={'md'} fontWeight={500} textColor={'purple.100'}>{item.name} #{Number(item.tokenId)}</Text>
          <Text fontSize={'xs'} fontWeight={400} textColor={'purple.300'}>{item.nftContract.slice(0, 6)}...{item.nftContract.slice(-4)}</Text>
        </VStack>
        <Stat mt={2}>
          <HStack>
            <StatNumber fontSize={'sm'} textColor={'purple.200'}>{Number(ethers.utils.formatEther(item.price)).toFixed(0)} $KULLY</StatNumber>
            <Image alt="kully" src={Kully} />
          </HStack>
        </Stat>
        {Number(item.status) == 0 && <Button my={2} onClick={() => web3Service.buyShopItem(Number(item.id), item.price)}>Buy</Button>}
        {Number(item.status) == 1 && <Button isDisabled my={2}>Sold</Button>}
      </Container>
    </Box>

  </>
};

const BazaarView: React.FC = () => {
  const web3Service = Web3Service.shared();

  const [account, setAccount] = useState<string | undefined>(undefined);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [activeItems, setActiveItems] = useState<ShopItem[]>([]);
  const [soldItems, setSoldItems] = useState<ShopItem[]>([]);

  useEffect(() => {
    web3Service.getShopActiveItems()
    web3Service.getShopSoldItems()
    web3Service.isShopManager()

    const account$ = web3Service.account$.pipe(
      tap((account) => {
        setAccount(account);
      })
    );

    const items$ = web3Service.shopActiveItems$.pipe(
      tap((items) => {
        setActiveItems(items)
      })
    );

    const soldItems$ = web3Service.shopSoldItems$.pipe(
      tap((items) => {
        setSoldItems(items)
      })
    );

    const isManager$ = web3Service.isShopManager$.pipe(
      tap((isManager) => {
        setIsManager(isManager)
      })
    );

    const subscription = merge(
      account$,
      items$,
      soldItems$,
      isManager$,
    ).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [account]);

  const items = activeItems.concat(soldItems)

  return (
    <>
      {
        items.length > 0 ?
          <VStack
            width={'100%'}
            my={{ base: 4, md: 8 }}
            mx={"auto"}
            px={4}
            maxW={'6xl'}
          >
            <Wrap justify='center' spacing={8}>
              {items.map((item, index) => <WrapItem key={`${index}_${Number(item.id)}`}><ShopItemView item={item} /></WrapItem>)}
            </Wrap>
          </VStack>
          :
          <Center mt={16} p={4}>
            <Alert
              py={8}
              maxWidth={'360px'}
              status='warning'
              variant='subtle'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              backdropFilter="auto"
              backdropBlur="4px"
              css={{
                border: '3px solid black',
                borderImageSlice: 1
              }}
              boxShadow={"md"}
            >
              <AlertIcon boxSize='40px' mr={0} />
              <AlertTitle mt={4} mb={1} fontSize='lg'>
                No Items
              </AlertTitle>
              <AlertDescription maxWidth='sm'>
                Thanks for checking our shop. Sadly there is no any items available right now.
              </AlertDescription>
              <AlertDescription maxWidth='sm'>
                Come back later!
              </AlertDescription>
            </Alert>
          </Center>
      }

      {
        isManager ?
          <ShopManagerView />
          :
          undefined
      }
    </>
  );
};

export default BazaarView;

const ShopManagerView: React.FC = () => {
  const web3Service = Web3Service.shared();

  const [account, setAccount] = useState<string | undefined>(undefined);

  const [contract, setContract] = useState('')
  const [tokenId, setTokenId] = useState('')
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')

  const handleContract = (event) => setContract(event.target.value)
  const handleTokenId = (event) => setTokenId(event.target.value)
  const handleName = (event) => setName(event.target.value)
  const handlePrice = (event) => setPrice(event.target.value)

  const isNumber = !isNaN(+tokenId)

  useEffect(() => {
    web3Service.isShopManager()

    const account$ = web3Service.account$.pipe(
      tap((account) => {
        setAccount(account);
      })
    );

    const subscription = merge(
      account$,
    ).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [account]);

  return (
    <>
      <Center mt={8}><Heading>Add Item</Heading></Center>
      <Center>
        <VStack mt={8} maxW={'6xl'} spacing={8}>
          <Box minW={'360px'}>
            <Text textAlign={'center'}>Collection Address</Text>
            <Input
              isInvalid={!isAddress(contract)}
              value={contract}
              onChange={handleContract}
              size='sm'
              bg={'rgba(0, 0, 0, 0.3)'}
            />
          </Box>

          <Box minW={'360px'}>
            <Text textAlign={'center'}>Token</Text>
            <NumberInput
              mt={2}
              size='sm'
              bg={'rgba(0, 0, 0, 0.3)'}
            >
              <NumberInputField
                onChange={handleTokenId}
              />
            </NumberInput>
          </Box>

          <Box minW={'360px'}>
            <Text textAlign={'center'}>Name</Text>
            <Input
              isInvalid={!(name.length > 0)}
              value={name}
              onChange={handleName}
              size='sm'
              bg={'rgba(0, 0, 0, 0.3)'}
            />
          </Box>

          <Box minW={'360px'}>
            <Text textAlign={'center'}>Price</Text>
            <NumberInput
              mt={2}
              size='sm'
              bg={'rgba(0, 0, 0, 0.3)'}
            >
              <NumberInputField
                onChange={handlePrice}
              />
            </NumberInput>
          </Box>
          <Center><Button my={4} isDisabled={contract.length < 1 || tokenId.length < 1 || name.length < 1 || price.length < 1} onClick={() => web3Service.addShopItem(contract, tokenId, name, price)}>Add Item</Button></Center>
        </VStack>
      </Center>
    </>
  );
};