import {
  Box,
  Center,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";

const StoryView: React.FC = () => {
  return (
    <>
      <Box pt={{ base: 8, md: 16 }} pb={16}>
        <Box
          backgroundColor={"red.500"}
          width={256}
          height={256}
          position={"absolute"}
          left={"50%"}
          transform={"translate(-50%, 0)"}
          zIndex={-1}
          filter={"auto"}
          blur={"164px"}
          borderRadius={128}
        />

        <Box position={"relative"}>
          <Center>
            <Box
              bg={'rgba(23, 25, 35, 0.7)'}
              rounded={"xl"}
              boxShadow={"md"}
              maxWidth={640}
              p={8}
              zIndex={2}
            >
              <VStack>
                <Heading w={'100%'}>From investors, by investors to investors</Heading>
                <Text>Once upon a time, in the ever-evolving world of NFTs, there were two NFTs Investors, Nolf and Wolf, who had grown weary of poorly executed NFT projects. After three long years of investing in NFTs, they decided they have had enough. They took matters into their own hands, vowing to create the NFT project they had always dreamed of.</Text>
                <Text>The duo launched their first NFT venture, the Skronos Syndicate Collection - an exquisite series of 200 NFTs. Against all odds, Skronos swiftly rose to fame, becoming the most renowned small collection project on the Cronos blockchain with a 600% ROl.</Text>
                <Text>Their success knew no bounds as they dominated the Cronos chain, proving that determination and passion could turn dreams into reality.</Text>
                <Text>However, Nolf and Wolf will not stop there. They decided to embark on a new journey, setting their sights on the Polygon blockchain. Their goal? To introduce a game-changing, larger collection that would revolutionize the world of Polygon NFTS.</Text>
                <Text>With Nolf's 10+ years of experience in startup venture building and innovation ecosystem mapping, Wolf's civil engineering background and passion for project management, and a remarkable team of talented well known web3 gurus, The House of Skulls will know no boundaries, with aspirations that will soar beyond the skies.</Text>
              </VStack>
            </Box>
          </Center>
        </Box>
      </Box>
    </>
  );
};

export default StoryView;
