import {
  Box,
  Center,
} from "@chakra-ui/react";

const ComingSoonView: React.FC = () => {
  return (
    <>
      <Box
        mt={8}
        px={4}
      >
        <Box py={4}>
          <Center>
            <Box textColor={'rgb(255, 255, 255)'}>Under Construction</Box>
          </Center>
        </Box>
      </Box>
    </>
  );
};

export default ComingSoonView;
