import {
  Box,
  Center,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";

const MissionView: React.FC = () => {
  return (
    <>
      <Box pt={{ base: 8, md: 16 }} pb={16}>
        <Box
          backgroundColor={"red.500"}
          width={256}
          height={256}
          position={"absolute"}
          left={"50%"}
          transform={"translate(-50%, 0)"}
          zIndex={-1}
          filter={"auto"}
          blur={"164px"}
          borderRadius={128}
        />

        <Box position={"relative"}>
          <Center>
            <Box
              bg={'rgba(23, 25, 35, 0.7)'}
              rounded={"xl"}
              boxShadow={"md"}
              maxWidth={640}
              p={8}
              zIndex={2}
            >
              <VStack>
                <Heading w={'100%'}>Mission:</Heading>
                <Text>At the House of Skulls, we are committed to reshaping the NFT landscape by introducing innovative, high-quality, and engaging digital art collections. Our mission is to empower and reward our community of investors and enthusiasts by fostering a sustainable, collaborative, and value-driven ecosystem.</Text>

                <Heading pt={8} w={'100%'}>Vision:</Heading>
                <Text>Our vision is to become a leading force in the NFT world, continuously pushing the boundaries of creativity and blockchain technology. Through our relentless pursuit of excellence, we aim to inspire future generations of artists and investors to explore the limitless possibilities that lie within the realm of digital art and NFTs.</Text>
              </VStack>
            </Box>
          </Center>
        </Box>
      </Box>
    </>
  );
};

export default MissionView;
