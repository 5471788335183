import {
  Box,
  ChakraProvider,
  extendTheme,
  Progress, useToast
} from "@chakra-ui/react";
import { merge, tap } from "rxjs";

import { useEffect, useState } from "react";

import Web3Service from "./core/web3.service";

import FooterView from "./components/FooterView";
import HeaderNavigation from "./components/HeaderNavigation";
import HoSMintView from "./components/HoSMintView";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import GalleryView from "./components/GalleryView";
import RoyaltiesClaimer from "./components/RoyaltiesClaimer";

import BackgroundImage from "./images/background.webp"
import TeamView from "./components/TeamView";
import StoryView from "./components/StoryView";
import ComingSoonView from "./components/ComingSoonView";
import RoadmapView from "./components/RoadmapView";
import PartnersView from "./components/PartnersView";
import MissionView from "./components/MissionView";
import RafflesView from "./components/RafflesView";
import RaffleItemView from "./components/RaffleItemView";
import RafflesAdmin from "./components/RafflesAdmin";
import RafflesManager from "./components/RafflesManager";
import MutantsRoyaltiesClaimer from "./components/MutantsRoyaltiesClaimer";
import MutantsGalleryView from "./components/MutantsGalleryView";
import HoSPetsMintView from "./components/HoSPetsMintView";
import HosPetsGalleryView from "./components/HosPetsGalleryView";
import HosPetsStakingView from "./components/HosPetsStakingView";
import StakingAdmin from "./components/StakingAdmin";
import HosStakingView from "./components/HosStakingView";
import HosStakingViewAdmin from "./components/HosStakingAdmin";
import BazaarView from "./components/BazaarView";

function App(): any {
  const toast = useToast();
  const web3Service = Web3Service.shared();

  const [isLoading, setIsLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState<string>();

  useEffect(() => {
    web3Service.connectToCachedProvider();

    const connected$ = web3Service.connected$.pipe(
      tap((connected) => {
        setConnected(connected);
      })
    );

    const account$ = web3Service.account$.pipe(
      tap((account) => {
        setAccount(account);
      })
    );

    const showToast$ = web3Service.showToast$.pipe(
      tap((info: { title: string }) => {
        toast({
          description: info.title,
          status: "success",
        });
      })
    );

    const errors$ = web3Service.errors$.pipe(
      tap((error) => {
        toast({
          description: error,
          status: "error",
        });
      })
    );

    const isLoading$ = web3Service.isLoading$.pipe(
      tap((isLoading) => {
        setIsLoading(isLoading);
      })
    );

    const subscription = merge(
      connected$,
      showToast$,
      errors$,
      account$,
      isLoading$
    ).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const config = {
    initialColorMode: "dark",
    useSystemColorMode: false,
  };

  const styles = {
    global: () => ({
      body: {
        bg: 'rgb(24, 28, 31)',
        body: 'rgb(255, 255, 255)'
      },
    }),
  };

  const fonts = {
  }

  const theme = extendTheme({
    config,
    fonts,
    styles,
    colors: {
      brand: {
        50: 'rgb(255, 255, 255)',
        100: 'rgb(255, 255, 255)',
        200: 'rgb(255, 255, 255)',
        500: 'rgb(255, 255, 255)',
        700: 'rgb(255, 255, 255)',
        900: 'rgb(255, 255, 255)',
      }
    }
  })

  return (
    <ChakraProvider theme={theme}>
      <Box
        position={"fixed"}
        backgroundImage={`url(${BackgroundImage})`}
        backgroundSize="cover"
        width={'100%'}
        height={'100%'}
        opacity={0.3}
        zIndex={-3}
      />

      <Router>
        <HeaderNavigation
          isConnected={connected}
          account={account}
          isCorrectChainId={web3Service.isCorrectChainId()}
          toggleConnect={web3Service.toggleConnect}
          switchNetwork={web3Service.switchNetwork}
        />

        <Progress
          colorScheme={'brand'}
          sx={{ position: "sticky", top: "0", zIndex: "999" }}
          size="xs"
          isIndeterminate opacity={isLoading ? 100 : 0} />

        <Routes>
          <Route path='/bazaar' element={<BazaarView />} />

          <Route path='/raffles-admin' element={<RafflesManager />} />
          <Route path='/mission' element={<MissionView />} />
          <Route path='/partners' element={<PartnersView />} />
          <Route path='/roadmap' element={<RoadmapView />} />
          <Route path='/lottery' element={<ComingSoonView />} />
          <Route path='/boxes' element={<ComingSoonView />} />

          <Route path='/raffles/:id' element={<RaffleItemView />} />
          <Route path='/raffles' element={<RafflesView />} />

          <Route path='/story' element={<StoryView />} />
          <Route path='/team' element={<TeamView />} />

          <Route path='/royalties-mutants' element={<MutantsRoyaltiesClaimer />} />
          <Route path='/explore-mutants' element={<MutantsGalleryView />} />
          
          <Route path='/hos-staking-admin' element={<HosStakingViewAdmin />} />
          <Route path='/hos-staking' element={<HosStakingView />} />
          <Route path='/hos-mint' element={<HoSMintView />} />
          <Route path='/royalties' element={<RoyaltiesClaimer />} />
          <Route path='/explore' element={<GalleryView />} />

          <Route path='/hos-pets-mint' element={<HoSPetsMintView />} />
          <Route path='/hos-pets-staking' element={<HosPetsStakingView />} />
          <Route path='/hos-pets-admin' element={<StakingAdmin />} />
          <Route path='/hos-pets-explore' element={<HosPetsGalleryView />} />

          <Route path='/' element={<HoSPetsMintView />} />
        </Routes>
      </Router>
      <FooterView />
    </ChakraProvider>
  );
}

export default App;
