import PlaceholderNFT from "../images/placeholder.webp";

import {
  Image,
  Box,
  Text,
} from "@chakra-ui/react";
import useDebounce from "../core/useDebounce";

export const DynamicNFTImage: React.FC<{
  contract: string;
  token: string;
  boxSize: number | string;
  errorTitle: string;
}> = (props) => {
  const { contract, token, boxSize } = props;

  const debouncedContract = useDebounce(contract, 1000);
  const debouncedToken = useDebounce(parseInt(token), 1000);

  const fallbackIamge = <Image
    boxSize={boxSize}
    border={"2px solid black"}
    // loading={"lazy"}
    objectFit={'cover'}
    alt={"NFT"}
    src={(debouncedContract && debouncedToken) ? `https://doonft.com/nft/${debouncedContract}/${debouncedToken}?blockchain=polygon` : PlaceholderNFT}
    fallbackStrategy={'beforeLoadOrError'}
    fallbackSrc={PlaceholderNFT}
  />

  return (
    <Box boxSize={boxSize} bgColor={'rgba(0, 0, 0, 0.5)'}>
      <Image
        boxSize={boxSize}
        border={"2px solid black"}
        // loading={"lazy"}
        objectFit={'cover'}
        alt={"NFT"}
        src={(debouncedContract && debouncedToken) ? `https://wsrv.nl/?url=https://doonft.com/nft/${debouncedContract}/${debouncedToken}?blockchain=polygon&width=256` : PlaceholderNFT}
        fallbackStrategy={'beforeLoadOrError'}
        fallback={fallbackIamge}
      />
    </Box>
  );
};