import {
    Container,
    Stack,
    Box,
    Heading,
    Text,
    Button,
    Tooltip,
    Center,
    SimpleGrid,
    VStack,
    HStack,
} from '@chakra-ui/react';

import { merge, tap } from "rxjs";

import { useEffect, useState } from "react";
import { MintInfo } from "../core/MintInfo";
import Web3Service from "../core/web3.service";

export default function HosHeader() {
    const web3Service = Web3Service.shared();

    const [mintCount, setMintCount] = useState(1)

    const [mintInfo, _] = useState<MintInfo | undefined>({ paused: false, supply: 5353, maxSupply: 5353, maxMint: 50, publicTimestamp: 1683990000, wlTimestamp: 1683988800 });

    let buttonEnabled: boolean
    let buttonTitle: string

    let dateInfo
    let freeMintLabel
    let mintsLeft = 0

    if (mintInfo) {
        const now = new Date()
        const publicDate = new Date(Number(mintInfo.publicTimestamp) * 1000)
        const wlDate = new Date(Number(mintInfo.wlTimestamp) * 1000)

        dateInfo = <>
            <Text mt={8} textAlign={'center'}>Whitelist Date: <b>{wlDate.toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</b></Text>
            <Text mt={8} textAlign={'center'}>Public Mint Date: <b>{publicDate.toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</b></Text>
        </>

        buttonTitle = "Sold Out"
        buttonEnabled = false

        if (mintCount > mintsLeft) {
            setMintCount(mintsLeft)
        }
    } else {
        buttonTitle = "Loading"
        buttonEnabled = false
    }

    let startInfo = <>
        {dateInfo}

        <SimpleGrid
            pt={8}
            textAlign='center'
            columns={{ base: 1, md: 1 }}
            spacing={{ base: 2, md: 2 }}
            mx={"auto"}
            px={4}
        >
            <Heading fontSize={'2xl'}>Price</Heading>
            <Text>Whitelist: <b>8 MATIC</b></Text>
            <Text>Public: <b>10 MATIC</b></Text>
        </SimpleGrid>
    </>

    let amountSlider = <>
        <Center paddingTop={8}>
            <VStack>
                {mintsLeft > 0 && <HStack>
                    <Button onClick={() => {
                        const value = mintCount - 1;
                        setMintCount(Math.max(1, value))
                    }}>-</Button>
                    <Heading fontSize={'md'} px={2}>{mintCount}</Heading>
                    <Button onClick={() => {
                        const value = mintCount + 1;
                        setMintCount(Math.min(mintsLeft, value))
                    }}>+</Button>
                </HStack>}
            </VStack>
        </Center>
    </>

    let mintedStatsView = <>
        <Center pt={4}>
            <VStack>
                {startInfo}
                <Heading pt={4} fontSize={'xl'} > {mintInfo?.supply.toString() ?? '-'}/{mintInfo?.maxSupply.toString() ?? '-'} minted</Heading>
            </VStack>
        </Center>
    </>


    return (
        <Container>
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
            >
                <Stack flex={1}>
                    <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: '30%',
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'red.900',
                                zIndex: -1,
                            }}>
                            House of Skulls
                        </Text>
                        <br />
                        <Text color={'red.600'} fontSize={'lg'}>
                            5353 unique collectibles
                        </Text>
                    </Heading>
                    <Box paddingTop={8} textAlign={"center"} fontSize={{ base: 'md', sm: 'md', md: 'lg' }}>
                        Welcome to the House of Skulls, where we bring you an exceptional collection of 5353 unique skeleton PFP collectibles, masterfully crafted on the Polygon blockchain. Our project is devoted to fostering a financially sustainable and long-lasting ecosystem that generously rewards holders.
                    </Box>
                    <Box paddingTop={8} textAlign={"center"} fontSize={{ base: 'md', sm: 'md', md: 'lg' }}>
                        As a distinguished member of our thriving community, you can look forward to a suite of exclusive benefits and a prosperous journey ahead. Join us in this extraordinary venture to discover the genuine possibilities that the world of NFTs has to offer.
                    </Box>

                    <Center>
                        <Box position={"relative"} maxWidth={640}>
                            {mintedStatsView}
                            {mintsLeft > 0 ? amountSlider : undefined}
                            {freeMintLabel}

                            <Center>
                                <HStack mt={8}>
                                    <Button
                                        onClick={() => web3Service.mint(mintCount)}
                                        disabled={!buttonEnabled}
                                        variant={"solid"}
                                        colorScheme={"brand"}
                                    >
                                        {buttonTitle}
                                    </Button>
                                </HStack>
                            </Center>
                        </Box>
                    </Center>
                </Stack>
            </Stack>
        </Container>
    );
}