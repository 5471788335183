import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";

import { useState } from "react";
import Web3Service from "../core/web3.service";
import { isAddress } from "ethers/lib/utils";
import { DynamicNFTImage } from "./DynamicNFTImage";

const RafflesManager: React.FC = () => {
  const web3Service = Web3Service.shared();

  const [address, setAddress] = useState('')
  const isAddressCorrect = isAddress(address)
  const handleAddress = (event) => setAddress(event.target.value)

  return (
    <>
      <Center mb={8}>
        <VStack
          spacing={8}
          align='stretch'
        >
          <Box>
            <Heading textAlign={"center"} fontSize={'xl'}>Collection Contract / Wallet Address</Heading>
            <Input
              mt={2}
              isInvalid={!isAddressCorrect}
              value={address}
              onChange={handleAddress}
              placeholder='Address'
              size='sm'
              bg={'rgba(0, 0, 0, 0.5)'}
            />
          </Box>

          <HStack>
            <Button
              isDisabled={!isAddressCorrect}
              onClick={() => web3Service.addManager(address)}
            >
              Add Manager
            </Button>
            <Button
              isDisabled={!isAddressCorrect}
              onClick={() => web3Service.whitelistProject(address)}
            >
              Whitelist Collection
            </Button>
            <Button
              isDisabled={!isAddressCorrect}
              onClick={() => web3Service.verifyProject(address)}
            >
              Verify Collection
            </Button>
          </HStack>

        </VStack>
      </Center>
    </>
  );
};

export default RafflesManager;
