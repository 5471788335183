import {
  Box,
  Button,
  Center,
  Heading,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";

import { useState } from "react";
import Web3Service from "../core/web3.service";
import { isAddress } from "ethers/lib/utils";
import { DynamicNFTImage } from "./DynamicNFTImage";

function formatTime(seconds) {
  const d = Math.floor(seconds / 86400)
  const h = Math.floor(seconds / 3600) % 24
  const m = Math.floor((seconds % 3600) / 60)

  return [
    d > 0 ? `${d}d` : false,
    h > 0 ? `${h}h` : false,
    m > 0 ? `${m}m` : false
  ].filter(Boolean).join(' ')
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

function formatDate(date: Date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    'T' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes())
    ].join(':')
  );
}

const RafflesAdmin: React.FC = () => {
  const web3Service = Web3Service.shared();

  const [address, setAddress] = useState('')
  const isAddressCorrect = isAddress(address)
  const handleAddress = (event) => setAddress(event.target.value)

  const [tokenId, setTokenId] = useState('')
  const isTokenIdCorrect = tokenId.length > 0 && !isNaN(+tokenId)
  const handleTokenId = (event) => setTokenId(event.target.value)

  const [name, setName] = useState('')
  const isNameCorrect = name && name.length > 0
  const handleName = (event) => setName(event.target.value)

  const [tickets, setTickets] = useState('')
  const isTicketsCorrect = !isNaN(+tickets)
  const handleTickets = (event) => setTickets(event.target.value)

  const [desc, setDesc] = useState('')
  const handleDesc = (event) => setDesc(event.target.value)

  const [price, setPrice] = useState('')
  const isPriceCorrect = price.length > 0 && !isNaN(+price)
  const handlePrice = (event) => setPrice(event.target.value)

  var days7 = new Date();
  days7.setDate(days7.getDate() + 7);

  const [endDate, setEndDate] = useState(formatDate(days7))
  const handleDuration = (event) => setEndDate(event.target.value)

  const minDate = formatDate(new Date())
  const maxDate = formatDate(days7)

  const now = new Date().getTime()
  const duration = (new Date(endDate + ":00").getTime() - now) / 1000;

  return (
    <>
      <Center mb={8}>
        <VStack
          spacing={8}
          align='stretch'
        >
          {
            isAddressCorrect && isTokenIdCorrect &&
            <Center><DynamicNFTImage contract={address} token={tokenId} boxSize={'140px'} errorTitle='Failed to load' /></Center>
          }

          <Box>
            <Heading textAlign={"center"} fontSize={'xl'}>Collection Contract Address</Heading>
            <Input
              mt={2}
              isInvalid={!isAddressCorrect}
              value={address}
              onChange={handleAddress}
              placeholder='Address'
              size='sm'
              bg={'rgba(0, 0, 0, 0.5)'}
            />
          </Box>

          <Box>
            <Heading textAlign={"center"} fontSize={'xl'}>Token ID</Heading>
            <Input
              id="endPickerId"
              mt={2}
              isInvalid={!isTokenIdCorrect}
              value={tokenId}
              onChange={handleTokenId}
              placeholder='Token ID'
              size='sm'
              bg={'rgba(0, 0, 0, 0.5)'}
            />
          </Box>

          <Box>
            <Heading textAlign={"center"} fontSize={'xl'}>Collection Name</Heading>
            <Input
              mt={2}
              isInvalid={!isNameCorrect}
              value={name}
              onChange={handleName}
              placeholder='Name'
              size='sm'
              bg={'rgba(0, 0, 0, 0.5)'}
            />
          </Box>

          <Box>
            <Heading textAlign={"center"} fontSize={'xl'}>Item Description</Heading>
            <Input
              mt={2}
              value={desc}
              onChange={handleDesc}
              placeholder='Description'
              size='sm'
              bg={'rgba(0, 0, 0, 0.5)'}
            />
          </Box>

          <Box>
            <Heading textAlign={"center"} fontSize={'xl'}>Max Tickets Count</Heading>
            <Input
              mt={2}
              isInvalid={!isTicketsCorrect}
              value={tickets}
              onChange={handleTickets}
              placeholder='Tickets Count'
              size='sm'
              bg={'rgba(0, 0, 0, 0.5)'}
            />
          </Box>

          <Box>
            <Heading textAlign={"center"} fontSize={'xl'}>Ticket Price</Heading>
            <Input
              mt={2}
              isInvalid={!isPriceCorrect}
              value={price}
              onChange={handlePrice}
              placeholder='Ticket Price'
              size='sm'
              bg={'rgba(0, 0, 0, 0.5)'}
            />
          </Box>

          <Box>
            <Heading textAlign={"center"} fontSize={'xl'}>Duration (max 7 days)</Heading>
            <Input
              mt={2}
              type="datetime-local"
              value={endDate}
              onChange={handleDuration}
              placeholder='Duration'
              size='sm'
              bg={'rgba(0, 0, 0, 0.5)'}
              min={minDate}
              max={maxDate}
            />
            <Text textAlign={'center'}>{formatTime(duration)}</Text>
          </Box>

          <Button
            isDisabled={!isAddressCorrect || !isTokenIdCorrect || !isNameCorrect || !isTicketsCorrect || !isPriceCorrect}
            onClick={() => web3Service.addRaffle(address, Number(tokenId), name, desc, Number(tickets), price, duration)}
          >
            Add Raffle
          </Button>

        </VStack>
      </Center>
    </>
  );
};

export default RafflesAdmin;
