import {
    Box,
    Center,
    SimpleGrid,
    Text,
    Container,
    Button,
    useColorModeValue,
    Stat,
    StatLabel,
    StatNumber,
    Image
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { merge, tap } from "rxjs";
import { RoyaltiesInfo } from "../core/RoyaltiesInfo";
import Web3Service from "../core/web3.service";

// TODO: MOVE
function nFormatter(num: number, digits: number) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : num.toFixed(2);

}

interface RoyaltiesClaimerProps { }

const RoyaltiesClaimer: React.FC<RoyaltiesClaimerProps> = (props) => {
    const web3Service = Web3Service.shared();

    const [account, setAccount] = useState<string | undefined>(undefined);
    const [royalties, setRoyalties] = useState<RoyaltiesInfo | undefined>(undefined);

    useEffect(() => {
        web3Service.getRoyalties();

        const account$ = web3Service.account$.pipe(
            tap((account) => {
                setAccount(account);
            })
        );

        const royalties$ = web3Service.royalties$.pipe(
            tap((royalties) => {
                setRoyalties(royalties);
            })
        );

        const subscription = merge(
            royalties$,
            account$
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        };
    }, [account]);

    return (
        <>
            <Center>
                <Box my={8} textColor={'rgb(255, 255, 255)'}>
                    {account && RoyaltiesSection("Royalties", `${royalties?.availableToClaim ?? '-'} MATIC`, royalties?.totalRoyalties ?? 0, web3Service.claimAllRoyalties)}
                    {!account && (<Container>
                        <Center mt={8}>
                            <Text maxW={'2xl'}>Connect Wallet to check your royalties</Text>
                        </Center>
                        <Center mt={8}>
                            <Button size={{ base: 'sm', sm: 'sm', md: 'md' }} onClick={web3Service.toggleConnect}>
                                {"Connect Wallet"}
                            </Button>
                        </Center>
                    </Container>)}
                </Box>
            </Center>
        </>
    );
};

export default RoyaltiesClaimer;

function RoyaltiesSection(title: string, available: string, total: number, claim: React.MouseEventHandler<HTMLButtonElement>) {
    let formattedTotalRoyalties = total > 1000 ? nFormatter(total, 2) : total;

    return (
        <>
            <SimpleGrid
                textColor={'rgb(255, 255, 255)'}
                columns={{ base: 1, md: 2 }}
                spacing={{ base: 4, md: 8 }}
                my={{ base: 4, md: 8 }}
                mx={"auto"}
                maxW={"6xl"}
            >
                <Stat bg={"rgba(0, 0, 0, 0.25)"}
                    rounded={"xl"}
                    boxShadow={"md"}
                    maxWidth={480}
                    p={8}>
                    <StatLabel>Available to claim</StatLabel>
                    <StatNumber>{available}</StatNumber>
                </Stat>

                <Stat bg={"rgba(0, 0, 0, 0.25)"}
                    rounded={"xl"}
                    boxShadow={"md"}
                    maxWidth={480}
                    p={8}>
                    <StatLabel>Total Distributed</StatLabel>
                    <StatNumber>{formattedTotalRoyalties} MATIC</StatNumber>
                </Stat>
            </SimpleGrid>

            <Center>
                <Button
                    colorScheme='brand'
                    variant={'solid'}
                    onClick={claim}
                >
                    {`Claim ${available}`}
                </Button>
            </Center>
        </>
    );
}