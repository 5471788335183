import {
  Box,
  Center,
  Image,
} from "@chakra-ui/react";

import Roadmap from "./../images/Roadmap.webp";

const RoadmapView: React.FC = () => {
  return (
    <>
      <Box
        mt={8}
        px={4}
      >
        <Box py={4}>
          <Center>
            <Image src={Roadmap} alt={'Roadmap'} />
          </Center>
        </Box>
      </Box>
    </>
  );
};

export default RoadmapView;
