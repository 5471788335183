import {
  Box,
  Center,
  Code,
  Container,
  HStack,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  VStack,
  Wrap,
  WrapItem,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BigNumber } from "ethers";
import {
  Link as RouterLink,
} from "react-router-dom";
import { RaffleItem, hasRaffleEnded, isRaffleOngoing } from "../core/raffleItem";
import { DynamicNFTImage } from "./DynamicNFTImage";
import Web3Service from "../core/web3.service";
import { merge, tap } from "rxjs";
import RafflesAdmin from "./RafflesAdmin";

import Countdown from 'react-countdown';

const RafflesView: React.FC = () => {
  const web3Service = Web3Service.shared();

  const [account, setAccount] = useState<string | undefined>(undefined);
  const [verifiedProjects, setverifiedProjects] = useState<string[]>([]);

  const options = ['Ongoing', 'Ended', 'Participated'] as const
  const [option, setOption] = useState<string>(options[0])

  const [isLoading, setIsLoading] = useState<Boolean>(true)
  const [lastRaffles, setLastRaffles] = useState<{ lastRaffles: RaffleItem[], playerRaffles: RaffleItem[] }>({ lastRaffles: [], playerRaffles: [] })

  const ongoing = lastRaffles.lastRaffles.filter(raffle => isRaffleOngoing(raffle))
  const ended = lastRaffles.lastRaffles.filter(raffle => hasRaffleEnded(raffle))

  const handleChange = (value) => {
    setOption(value)
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'raffle-type',
    defaultValue: 'Ongoing',
    onChange: handleChange,
  })

  const group = getRootProps()

  useEffect(() => {
    setIsLoading(true)
    web3Service.getLastRaffles()

    const account$ = web3Service.account$.pipe(
      tap((account) => {
        setAccount(account);
      })
    );

    const lastRaffles$ = web3Service.lastRaffles$.pipe(
      tap((lastRaffles) => {
        setLastRaffles(lastRaffles)
        setIsLoading(false)
      })
    );

    const verifiedProjects$ = web3Service.verifiedProjects$.pipe(
      tap((verifiedProjects) => {
        setverifiedProjects(verifiedProjects);
      })
    );

    const subscription = merge(
      account$,
      lastRaffles$,
      verifiedProjects$,
    ).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [account])

  let loadingContent = <>
    <Container textAlign={'center'} mt={8}>
      <Spinner color={'purple.200'} />
    </Container>
  </>

  if (isLoading) {
    return loadingContent
  }

  let premiumRaffles: RaffleItem[]
  let publicRaffles: RaffleItem[]

  if (option == options[0]) {
    premiumRaffles = ongoing.filter(r => !r.isPublic)
    publicRaffles = ongoing.filter(r => r.isPublic)
  } else if (option == options[1]) {
    premiumRaffles = ended.filter(r => !r.isPublic)
    publicRaffles = ended.filter(r => r.isPublic)
  } else {
    premiumRaffles = lastRaffles.playerRaffles.filter(r => !r.isPublic)
    publicRaffles = lastRaffles.playerRaffles.filter(r => r.isPublic)
  }

  return (
    <>
      <Tabs my={8} colorScheme='purple'>
        <Center>
          <TabList>
            <Tab>Lottery</Tab>
            <Tab>Public</Tab>
            <Tab>Add Raffle</Tab>
          </TabList>
        </Center>
        <TabPanels>
          <TabPanel px={0}>
            <Center mb={8}>
              <HStack {...group}>
                {options.map((value) => {
                  const radio = getRadioProps({ value })
                  return (
                    <RadioCard key={value} {...radio}>
                      {`${value}`}
                    </RadioCard>
                  )
                })}
              </HStack>
            </Center>
            {premiumRaffles.length > 0 ? <Box>
              <Center>
                <Wrap justify='center' spacingY={8}>
                  {premiumRaffles.map((item, index) => <WrapItem key={`${item.nftContract}_${item.token}_${index}`}><RaffleCard item={item} /></WrapItem>)}
                </Wrap>
              </Center>
            </Box> : <Heading textAlign={'center'} fontSize={'lg'}>No raffles yet</Heading>}
          </TabPanel>
          <TabPanel px={0}>
            <Center mb={8}>
              <HStack {...group}>
                {options.map((value) => {
                  const radio = getRadioProps({ value })
                  return (
                    <RadioCard key={value} {...radio}>
                      {value}
                    </RadioCard>
                  )
                })}
              </HStack>
            </Center>
            {publicRaffles.length > 0 ? <Box>
              <Center>
                <Wrap justify='center' spacingY={8}>
                  {publicRaffles.map((item, index) => <WrapItem key={`${item.nftContract}_${item.token}_${index}`}><RaffleCard item={item} /></WrapItem>)}
                </Wrap>
              </Center>
            </Box> : <Heading textAlign={'center'} fontSize={'lg'}>No raffles yet</Heading>}
          </TabPanel>
          <TabPanel px={0}>
            <RafflesAdmin />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

const RaffleCard = (props: { item: RaffleItem }) => {
  const renderer = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return undefined
    } else {
      return <span>{days}d {hours}h {minutes}m</span>;
    }
  }

  const { item } = props
  const isFinished = hasRaffleEnded(item)
  const isCancelled = BigNumber.from(item.winner).isZero() && (item.players.length == item.maxPlayers)
  
  // {isVerified && <Image boxSize={'16px'} src={Bluemark} />}
  return <RouterLink
    to={`/raffles/${Number(item.id)}`}
  >
    <VStack maxW={'140px'} position={'relative'} spacing={0}>
      <Box position={'absolute'} left={0} right={0} top={0}>
        {isCancelled && <Tag rounded={0} bgColor={'rgba(0, 0, 0, 0.8)'} colorScheme={'red'} w={'100%'} justifyContent={'center'}>Cancelled</Tag>}
        {isFinished && <Tag rounded={0} bgColor={'rgba(0, 0, 0, 0.8)'} colorScheme={'purple'} w={'100%'} justifyContent={'center'}>Ended</Tag>}
        {!isCancelled && !isFinished && <Tag rounded={0} bgColor={'rgba(0, 0, 0, 0.8)'} colorScheme={'green'} w={'100%'} justifyContent={'center'}>{`Tickets ${item.players.length}/${item.maxPlayers}`}</Tag>}
      </Box>
      <DynamicNFTImage contract={item.nftContract} token={String(Number(item.token))} boxSize={'140px'} errorTitle='Failed to load' />
      <Tag rounded={0} bgColor={'rgba(0, 0, 0, 0.8)'} position={'absolute'} py={1} left={0} right={0} bottom={0} noOfLines={2} textAlign={'center'} justifyContent={'center'}>{item.name} #{Number(item.token)}</Tag>
    </VStack>
    {!isFinished && !isCancelled && <Center>{<Code w='100%' textAlign={'center'} colorScheme='black'>{<Countdown date={Number(item.timestamp) * 1000} renderer={renderer} />}</Code>}</Center>}
  </RouterLink>
}

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderRadius='2xl'
        _checked={{
          bg: 'rgba(255, 255, 255, 0.05)',
          color: 'white',
        }}
        px={4}
        py={2}
        textAlign='center'
      >
        {props.children}
      </Box>
    </Box>
  )
}
export default RafflesView;