import { BigNumber } from "ethers"

export interface RaffleItem {
    id: number,
    creator: string,
    nftContract: string,
    token: number,
    name: string,
    desc: string,
    maxPlayers: number
    ticketPrice: BigNumber
    players: string[]
    winner: string
    isPublic: boolean
    timestamp: BigNumber
}

export function hasRaffleEnded(raffle: RaffleItem): boolean {
    return !BigNumber.from(raffle.winner).isZero() || (new Date() > new Date(Number(raffle.timestamp) * 1000)) || (BigNumber.from(raffle.winner).isZero() && (raffle.players.length == raffle.maxPlayers))
}

export function isRaffleOngoing(raffle: RaffleItem): boolean {
    return BigNumber.from(raffle.winner).isZero() && (raffle.players.length != raffle.maxPlayers) && (new Date() < new Date(Number(raffle.timestamp) * 1000))
}