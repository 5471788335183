import {
  Box,
  Center,
  Image,
  Link,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import DooNFT from "./../images/partners/doonft.webp";
import Skronos from "./../images/partners/skronos.webp";

const PartnersView: React.FC = () => {
  const partners = [
    { name: 'DooNFT', url: 'https://doonft.com', image: DooNFT },
    { name: 'Skronos Syndicate', url: 'https://skronos-syndicate.com', image: Skronos },
  ]

  return (
    <>
      <Center>
        <Box mt={8}>
          <Center>
            <Wrap
              justify='center'
              spacingX={4}
              spacingY={8}
              mx={"auto"}
              maxW={"8xl"}
            >

              {partners.map((partner, i) => (
                <WrapItem
                  key={i}>
                  <Link href={partner.url} isExternal>
                    <VStack>
                      <Image boxSize={48} src={partner.image} alt={partner.name} borderRadius={'md'} />
                      <Box
                        w={'100%'}
                        bg="red.800"
                        mt={-4}
                        shadow="md"
                        rounded="md"
                        overflow="hidden"
                      >
                        <Text
                          p={1}
                          maxW={48}
                          textAlign="center"
                          fontWeight="bold"
                          textTransform="uppercase"
                          color="white"
                          letterSpacing={1}
                          fontSize={'sm'}
                        >
                          {partner.name}
                        </Text>
                      </Box>
                    </VStack>
                  </Link>
                </WrapItem>
              ))}
            </Wrap>
          </Center>
        </Box>
      </Center>
    </>
  );
};

export default PartnersView;
