import {
    Container,
    Stack,
    Box,
    Heading,
    Text,
    Button,
    Tooltip,
    Center,
    SimpleGrid,
    VStack,
    HStack,
} from '@chakra-ui/react';

import { merge, tap } from "rxjs";

import { useEffect, useState } from "react";
import { MintInfo } from "../core/MintInfo";
import Web3Service from "../core/web3.service";

export default function HosPetsHeader() {
    const web3Service = Web3Service.shared();

    const [mintCount, setMintCount] = useState(1)

    const [account, setAccount] = useState<string | undefined>(undefined);
    const [mintInfo, setMintInfo] = useState<MintInfo | undefined>(undefined);
    const [wlCount, setWlCount] = useState(0)
    const [isWhitelisted, setIsWhitelisted] = useState(false)

    useEffect(() => {
        web3Service.getMintInfo();

        const account$ = web3Service.account$.pipe(
            tap((account) => {
                setAccount(account);
            })
        );

        const mintInfo$ = web3Service.mintInfo$.pipe(
            tap((mintInfo) => {
                setMintInfo(mintInfo);
            })
        );

        const subscription = merge(
            mintInfo$,
            account$
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        };
    }, [account]);

    useEffect(() => {
        web3Service.whitelistAmount()

        const freeMintCount$ = web3Service.freeMintCount$.pipe(
            tap((count) => {
                setWlCount(count);
            })
        );

        const isWhitelisted$ = web3Service.isWhitelisted$.pipe(
            tap((isWhitelisted) => {
                setIsWhitelisted(isWhitelisted);
            })
        );

        const subscription = merge(
            freeMintCount$,
            isWhitelisted$
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        };
    }, [account])

    let buttonEnabled: boolean
    let buttonTitle: string

    let dateInfo
    let freeMintLabel
    let wlLabel
    let mintsLeft = 0

    if (isWhitelisted) {
        wlLabel = <>
            <Center pt={8}>
                <Heading as="h2" size="md" fontWeight="400" textShadow={'lg'} color="brand">
                    You are whitelisted!
                </Heading>
            </Center>
        </>
    }

    if (mintInfo) {
        const now = new Date()
        const publicDate = new Date(Number(mintInfo.publicTimestamp) * 1000)
        const wlDate = new Date(Number(mintInfo.wlTimestamp) * 1000)

        dateInfo = <>
            <Text mt={8} textAlign={'center'}>Whitelist Date: <b>{wlDate.toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</b></Text>
            <Text mt={8} textAlign={'center'}>Public Mint Date: <b>{publicDate.toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</b></Text>
        </>

        if (mintInfo.paused) {
            buttonTitle = "Paused"
            buttonEnabled = false
        } else if (now >= publicDate || (wlCount > 0 && now >= wlDate)) {
            if (Number(mintInfo.maxSupply) > Number(mintInfo.supply)) {
                mintsLeft = Math.min(mintInfo.maxMint, Number(mintInfo.maxSupply) - Number(mintInfo.supply))
                buttonTitle = "Mint"
                buttonEnabled = true
            } else {
                buttonTitle = "Sold Out"
                buttonEnabled = false
            }
        } else {
            buttonTitle = "Coming Soon"
            buttonEnabled = false
        }

        if (wlCount > 0) {
            freeMintLabel = <>
                <Center pt={8}>
                    <Heading as="h2" size="md" fontWeight="400" textShadow={'lg'} color="brand">
                        You got <b>{Number(wlCount)}</b> whitelisted mints left!
                    </Heading>
                </Center>
            </>

            mintsLeft = Math.min(mintInfo.maxMint, Number(wlCount))
        }

        if (mintCount > mintsLeft) {
            setMintCount(mintsLeft)
        }
    } else {
        buttonTitle = "Loading"
        buttonEnabled = false
    }

    let startInfo = <>
        {dateInfo}

        <SimpleGrid
            pt={8}
            textAlign='center'
            columns={{ base: 1, md: 1 }}
            spacing={{ base: 2, md: 2 }}
            mx={"auto"}
            px={4}
        >
            <Heading fontSize={'2xl'}>Price</Heading>
            <Text>Whitelist: <b>11 MATIC</b></Text>
            <Text>Public: <b>13 MATIC</b></Text>
        </SimpleGrid>
    </>

    let amountSlider = <>
        <Center paddingTop={8}>
            <VStack>
                {mintsLeft > 0 && <HStack>
                    <Button onClick={() => {
                        const value = mintCount - 1;
                        setMintCount(Math.max(1, value))
                    }}>-</Button>
                    <Heading fontSize={'md'} px={2}>{mintCount}</Heading>
                    <Button onClick={() => {
                        const value = mintCount + 1;
                        setMintCount(Math.min(mintsLeft, value))
                    }}>+</Button>
                </HStack>}
            </VStack>
        </Center>
    </>

    let mintedStatsView = <>
        <Center pt={4}>
            <VStack>
                {startInfo}
                <Heading pt={4} fontSize={'xl'} > {mintInfo?.supply.toString() ?? '-'}/{mintInfo?.maxSupply.toString() ?? '-'} minted</Heading>
            </VStack>
        </Center>
    </>


    return (
        <Container>
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
            >
                <Stack flex={1}>
                    <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: '30%',
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'blue.900',
                                zIndex: -1,
                            }}>
                            House of Skulls
                        </Text>
                        <br />
                        <Text color={'blue.600'} fontSize={'lg'}>
                            PETS EDITION
                        </Text>
                    </Heading>
                    <Box paddingTop={8} textAlign={"center"} fontSize={{ base: 'md', sm: 'md', md: 'lg' }}>
                        We bring you an exceptional and diverse collection of 8299 unique skeleton PETS that consist of 8 different animals and 1000+ traits masterfully crafted on the Polygon blockchain. The Utility of the Pets is their power to be stackable for WETH rewards.
                    </Box>

                    <Center>
                        <Box position={"relative"} maxWidth={640}>
                            {mintedStatsView}
                            {mintsLeft > 0 ? amountSlider : undefined}
                            {wlLabel}
                            {freeMintLabel}

                            <Center>
                                <HStack mt={8}>
                                    <Button
                                        onClick={() => web3Service.mint(mintCount)}
                                        disabled={!buttonEnabled}
                                        variant={"solid"}
                                        colorScheme={"brand"}
                                    >
                                        {buttonTitle}
                                    </Button>
                                </HStack>
                            </Center>
                        </Box>
                    </Center>
                </Stack>
            </Stack>
        </Container>
    );
}