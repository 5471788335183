import { Avatar, Box, Flex, Heading, Image } from "@chakra-ui/react";

interface NFTStakeViewProps {
  name: string,
  image: string,
  color: string,
  isStaked: boolean
  isBoosted: boolean
}

const NFTStakeView: React.FC<NFTStakeViewProps> = (props) => {
  const { name, image, color, isBoosted } = props;

  return (
    <>
      <Flex
        w="full"
        alignItems="center"
        justifyContent="center"
        position={'relative'}
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          w="sm"
          mx="auto">

          {isBoosted &&
            <Box
              backgroundColor={"blue.300"}
              boxSize={{ base: '80%', md: '100%' }}
              position={"absolute"}
              left={"50%"}
              transform={"translate(-50%, 0)"}
              zIndex={-1}
              filter={"auto"}
              blur={"16px"}
            />
          }
          <Image src={`https://wsrv.nl/?url=${image}`} fallbackSrc={image} fallbackStrategy={'onError'} boxSize={{ base: '80%', md: '100%' }} css={{
            border: `3px solid ${color}`,
          }} />

          <Box
            zIndex={2}
            css={{
              border: `3px solid ${color}`,
            }}
            bg='rgba(0, 0, 0, 0.7)'
            mt={-5}
            shadow="lg"
            rounded="sm"
            px={8}
            overflow="hidden"
          >
            <Heading
              py={2}
              textAlign="center"
              fontWeight="bold"
              fontSize={'sm'}
              textTransform="uppercase"
              color="white"
              letterSpacing={1}
            >
              {name}
            </Heading>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default NFTStakeView;
