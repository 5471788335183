import {
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import TeamMember from "./TeamMember";

import Nolf from "./../images/pfps/Nolf.webp";
import Wolf from "./../images/pfps/Wolf.webp";
import ArtDoo from "./../images/pfps/ArtDoo.webp";
import MC from "./../images/pfps/Mcpuffin.webp";
import Dream from "./../images/pfps/Dram.webp";
import Whaler from "./../images/pfps/Whaler.webp";
import Quant from "./../images/pfps/Quant.webp";
import Kay from "./../images/pfps/Kay.webp";
import Hoss from "./../images/pfps/Hoss.webp";
import Kahuna from "./../images/pfps/Kahuna.webp";

type Member = { id: string, name: string, role: string, twitter?: string, image: string }

const TeamView: React.FC = () => {
  const members: Member[] = [
    { id: `nolf`, name: 'Nolf', role: 'Co-founder', twitter: 'https://twitter.com/Skronos_Nolf', image: Nolf },
    { id: `wolf`, name: 'TherlsWolf', role: 'Co-founder', twitter: 'https://twitter.com/Skronos_wolf', image: Wolf },
    { id: `artdoo`, name: 'ArtDoo', role: 'Lead Developer', twitter: 'https://twitter.com/0xArtCro', image: ArtDoo },
    { id: `mc`, name: 'McPuffins', role: 'Lead Mod & Community Manager', twitter: 'https://twitter.com/BrentMoman', image: MC },
    { id: `dream`, name: 'D𝖗𝖊𝖆𝖒 𝕼𝖈', role: 'Strategic Counselor', twitter: 'https://twitter.com/Dream_Qc_', image: Dream },
    { id: `whaler`, name: 'Whaler', role: 'Artist', twitter: undefined, image: Whaler },
    { id: `quant`, name: 'Quant', role: 'Innovation Consultant ', twitter: undefined, image: Quant },
    { id: `kay`, name: 'Kay', role: 'Social Media Manager', twitter: undefined, image: Kay },
    { id: `hoss`, name: 'Hoss/Stix', role: 'Heads of Coms', twitter: 'https://twitter.com/MadHareWear', image: Hoss },
    { id: `kahuna`, name: 'Kahuna K', role: 'Twitter Engagement Lead & Moderator', twitter: 'https://twitter.com/JoekhoCrypto', image: Kahuna },
  ]

  return (
    <>
      <Center>
        <Box mt={8}>
          <Center>
            <Wrap
              justify='center'
              spacingX={4}
              spacingY={8}
              mx={"auto"}
              maxW={"8xl"}
            >

              {members.map((member, i) => (
                <WrapItem
                  key={i}>
                  <Link href={member.twitter} isExternal>
                    <TeamMember name={member.name} role={member.role} twitter={member.twitter} image={member.image} />
                  </Link>
                </WrapItem>
              ))}
            </Wrap>
          </Center>
        </Box>
      </Center>
    </>
  );
};

export default TeamView;
