import {
    Box,
    Center,
    SimpleGrid,
    Link,
    Text,
    Container,
    Button,
    Stat,
    StatLabel,
    StatNumber,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { merge, tap } from "rxjs";
import Web3Service from "../core/web3.service";

import NFTItemView from "./NFTItemView";

const GalleryView: React.FC = () => {
    const web3Service = Web3Service.shared();

    const [account, setAccount] = useState<string | undefined>(undefined);
    const [tokens, setTokens] = useState<number[]>([]);

    useEffect(() => {
        web3Service.getUserTokens(undefined);

        const account$ = web3Service.account$.pipe(
            tap((account) => {
                setAccount(account);
            })
        );

        const tokens$ = web3Service.tokens$.pipe(
            tap((tokens) => {
                setTokens(tokens);
            })
        );

        const subscription = merge(
            tokens$,
            account$
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        };
    }, [account]);

    let content = <>
        <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={{ base: 4, md: 8 }}
            my={{ base: 4, md: 8 }}
            mx={"auto"}
            px={4}
            maxW={"6xl"}
        >
            <Stat bg={"rgba(0, 0, 0, 0.25)"}
                rounded={"xl"}
                boxShadow={"md"}
                p={4}>
                <StatLabel>Address</StatLabel>
                <StatNumber fontSize={{ base: 'sm', md: 'md' }}>{account}</StatNumber>
            </Stat>

            <Stat bg={"rgba(0, 0, 0, 0.25)"}
                rounded={"xl"}
                boxShadow={"md"}
                p={4}>
                <StatLabel>Owned House of Skulls</StatLabel>
                <StatNumber fontSize={{ base: 'sm', md: 'md' }}>{tokens.length}</StatNumber>
            </Stat>
        </SimpleGrid>

        <SimpleGrid
            columns={{ base: 2, md: 4 }}
            spacing={{ base: 4, md: 8 }}
            my={{ base: 4, md: 8 }}
            mx={"auto"}
            maxW={"4xl"}
        >
            {tokens.map((token, i) => (
                <Link key={i} href={`https://bafybeicnoppmpnizqgwxouhq6rknmfxjhjui5raepfnl5t2b6wn4cmmc54.ipfs.nftstorage.link/${token}.png`} isExternal>
                    <NFTItemView
                        name={`#${token}`}
                        image={`https://bafybeicnoppmpnizqgwxouhq6rknmfxjhjui5raepfnl5t2b6wn4cmmc54.ipfs.nftstorage.link/${token}.png`}
                    />
                </Link>
            ))}
        </SimpleGrid>
    </>

    let emptyView = <>
        <Center mt={8}>
            <Text maxW={'2xl'}>You do not own any House of Skulls</Text>
        </Center>
    </>

    let connectWallet = <>
        <Container>
            <Center mt={8}>
                <Text maxW={'2xl'}>Connect Wallet to see your House of Skulls</Text>
            </Center>
            <Center mt={8}>
                <Button size={{ base: 'sm', sm: 'sm', md: 'md' }} onClick={web3Service.toggleConnect}>
                    {"Connect Wallet"}
                </Button>
            </Center>
        </Container>
    </>

    return (
        <>
            <Center textColor={'rgb(255, 255, 255)'} my={8}>
                <Box>
                    {account ? (tokens.length > 0 ? content : emptyView) : connectWallet}
                </Box>
            </Center>
        </>
    );
};

export default GalleryView;
