import {
  Box,
  Button,
  Center,
  Heading,
  Input,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import { useState } from "react";
import Web3Service from "../core/web3.service";
import { HOS_STAKING_ABI, HOS_STAKING_ADDRESS } from "../config-staking-hos";
import { KULLY_ABI, KULLY_ADDRESS } from "../config-kully";

const HosStakingViewAdmin: React.FC = () => {
  const web3Service = Web3Service.shared();

  const [value, setValue] = useState('')
  const handleChange = (event) => setValue(event.target.value)

  const isNumber = !isNaN(+value)

  return (
    <>
      <Center mt={16} mb={8}>
        <Box>
          <Heading textAlign={"center"}>Add $KULLY to the Pool</Heading>

          <Input
            mt={16}
            isInvalid={!isNumber}
            value={value}
            onChange={handleChange}
            placeholder='KULLY amount'
            size='sm'
          />
          <Center><Button my={4} isDisabled={!isNumber} onClick={() => web3Service.approveStakingToken(KULLY_ADDRESS, KULLY_ABI, HOS_STAKING_ADDRESS, value)}>Approve</Button></Center>
          <Center><Button my={4} isDisabled={!isNumber} onClick={() => web3Service.startSeason(HOS_STAKING_ADDRESS, HOS_STAKING_ABI, value)}>Start Season</Button></Center>
        </Box>
      </Center>
    </>
  );
};

export default HosStakingViewAdmin;
