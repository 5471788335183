import { Box, Flex, Text, VStack } from "@chakra-ui/react";

interface TeamMemberProps {
  name: string,
  role: string,
  twitter?: string
  image: string
}

const TeamMember: React.FC<TeamMemberProps> = (props) => {
  const { name, role, image } = props;

  return (
    <>
      <Flex
        w="full"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          w="sm"
          mx="auto"
        >
          <Box
            bg="gray.300"
            h={48}
            w={48}
            rounded="lg"
            shadow="md"
            bgSize="cover"
            bgPos="center"
            style={{
              backgroundImage:
                "url(" + image + ")",
            }}
          />

          <Box
            w={56}
            bg="red.800"
            mt={-4}
            shadow="lg"
            rounded="lg"
            overflow="hidden"
          >
            <VStack>
              <Text
                textAlign="center"
                fontWeight="bold"
                textTransform="uppercase"
                color="white"
                letterSpacing={1}
              >
                {name}
              </Text>

              <Text
                textAlign="center"
                fontWeight="bold"
                textTransform="uppercase"
                color="white.500"
                fontSize={'xs'}
                letterSpacing={1}
              >
                {role}
              </Text>
            </VStack>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default TeamMember;
