import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Code,
  Container,
  Flex,
  HStack,
  Heading,
  Image,
  List,
  ListItem,
  OrderedList,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { useParams } from "react-router-dom";
import { DynamicNFTImage } from "./DynamicNFTImage";
import Web3Service, { Data } from "../core/web3.service";
import { merge, tap } from "rxjs";

import Countdown from 'react-countdown';
import { hasRaffleEnded } from "../core/raffleItem";
import Bluemark from "../images/blue-mark.webp"

const RaffleItemView: React.FC = () => {
  const web3Service = Web3Service.shared();

  const [address, setAddress] = useState<string | undefined>(undefined);
  const [verifiedProjects, setverifiedProjects] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(true)
  const [data, setData] = useState<Data | undefined>(undefined)
  const [counter, setCounter] = useState<number>(1)

  let { id } = useParams();
  const raffle = data?.raffle

  let isCancelled = raffle ? (BigNumber.from(raffle.winner).isZero() && raffle.players.length == Number(raffle.maxPlayers)) : false
  let hasEnded = raffle ? hasRaffleEnded(raffle) : false

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      web3Service.getRaffleData(Number(id))
    }

    const data$ = web3Service.raffleData$.pipe(
      tap((data) => {
        setData(data)
        setIsLoading(false)
      })
    );

    const account$ = web3Service.account$.pipe(
      tap((account) => {
        setAddress(account);
      })
    );

    const verifiedProjects$ = web3Service.verifiedProjects$.pipe(
      tap((verifiedProjects) => {
        setverifiedProjects(verifiedProjects);
      })
    );

    const subscription = merge(
      account$,
      data$,
      verifiedProjects$
    ).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [address])

  const players = raffle?.players ?? []
  var tickets = new Map<string, number>();
  for (const player of players) {
    const count = tickets.get(player.toLowerCase()) ?? 0
    tickets.set(player.toLowerCase(), count + 1)
  }
  const orderedPlayers = new Map([...tickets].sort((a, b) => b[1] - a[1]))

  const canClaimPrize = data && !data.prizeClaimed && raffle && address && address?.toLowerCase() == raffle.winner.toLowerCase()
  const canClaimReward = data && !data.rewardClaimed && raffle && address && !BigNumber.from(raffle.winner).isZero() && address && address.toLowerCase() == raffle.creator.toLowerCase()

  const ticketsLeft = raffle ? (Number(raffle.maxPlayers) - players.length) : 0

  const canClaimRefund = data && !data.didClaimCancelled && raffle && address && isCancelled && address && (tickets.get(address.toLowerCase()) ?? 0) > 0
  const canCancel = raffle && !isCancelled && BigNumber.from(raffle.winner).isZero() && raffle.creator.toLowerCase() == address?.toLowerCase()
  const canJoin = !isCancelled

  const endDate = raffle ? new Date(Number(raffle.timestamp) * 1000) : undefined
  const isVerified = raffle ? verifiedProjects.some(p => p.toLowerCase() == raffle.nftContract.toLowerCase()) : false

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return 'Expired'
    } else {
      return <span>{days}d {hours}h {minutes}m {seconds}s</span>;
    }
  }

  let loadingContent = <>
    <Container textAlign={'center'} mt={8}>
      <Spinner color={'purple.200'} />
    </Container>
  </>

  let noExistsContent = <>
    <Container textAlign={'center'} mt={8}>
      <Alert
        status='error'
        flexDirection='column'
        bgColor={'rgba(0, 0, 0, 0.2)'}
        alignItems='center'
        rounded={'lg'}
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          Raffle #{id}
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          Does not exist or failed to load.
        </AlertDescription>
      </Alert>
    </Container>
  </>

  let content = <>
    {raffle && <VStack
      my={{ base: 4, md: 8 }}
    >
      <Container textAlign={'center'}>
        {/* <Center mb={4}>
          <HStack alignItems={'start'}>
            <IconButton
              icon={<FiLink />}
              onClick={() => {
                navigator.clipboard.writeText(window.location.toString())
                toast({
                  description: "Link to raffle copied to clipboard",
                  status: "success",
                });
              }} aria-label={'Share'} />
            <Heading mb={4}>Raffle #{raffleId}</Heading>
          </HStack>
        </Center> */}

        <Alert mt={4}
          bgColor={'rgba(0, 0, 0, 0.2)'}
          backdropFilter="auto"
          backdropBlur="8px"
          alignItems='center'
          flexDirection='column'
          justifyContent='center'
          textAlign='center'
          status={'success'}>
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            {raffle.name} #{Number(raffle.token)}
          </AlertTitle>
          {isVerified && <Image boxSize={'16px'} src={Bluemark} />}
          <AlertDescription maxWidth='sm'>
            <Tooltip label='Copy'>
              <Code cursor={'pointer'} colorScheme="black" onClick={() => {
                navigator.clipboard.writeText(raffle.nftContract)
              }}
                fontSize={'xs'}>{raffle.nftContract}</Code>
            </Tooltip>
          </AlertDescription>
          <Center>
            <DynamicNFTImage contract={raffle.nftContract} token={String(raffle.token)} boxSize={'256px'} errorTitle='Failed to load image' />
          </Center>
          <AlertTitle mt={4} mb={1} fontSize='md'>
            {raffle.desc}
          </AlertTitle>
        </Alert>
      </Container>

      <Container textAlign={'center'}>
        {!hasEnded ?
          !isCancelled && <Heading mb={8}>Join</Heading>
          :
          !BigNumber.from(raffle.winner).isZero() && <Alert mb={8}
            bgColor={'rgba(0, 0, 0, 0.2)'}
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
            textAlign='center'
            status={'success'}>
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              Winner
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              <Text fontSize={'sm'} textColor='green.200'>{raffle.winner}</Text>
            </AlertDescription>
          </Alert>
        }

        {!address && (<Container>
          <Center mt={8}>
            <Text maxW={'2xl'}>Connect Wallet to Join or Claim Raffle</Text>
          </Center>
        </Container>)}
        <Center>
          <HStack alignItems={'start'}>
            {address && !isCancelled && !hasEnded && BigNumber.from(raffle.winner).isZero() &&
              <VStack>
                <Heading fontSize={'md'} px={2}>Buy Tickets</Heading>
                {ticketsLeft > 0 && <HStack mb={4}>
                  <Button onClick={() => {
                    const value = counter - 1;
                    setCounter(Math.max(1, value))
                  }}>-</Button>
                  <Heading fontSize={'md'} px={2}>{counter}</Heading>
                  <Button onClick={() => {
                    const value = counter + 1;
                    setCounter(Math.min(ticketsLeft, value))
                  }}>+</Button>
                </HStack>}

                <Button
                  colorScheme={'green'}
                  isDisabled={!canJoin}
                  onClick={() => {
                    if (!id || !raffle) return
                    web3Service.joinRaffle(Number(id), counter, raffle.ticketPrice)
                  }}
                >
                  Join
                </Button>
                {/* {alreadyJoined && <Text fontSize={'sm'} textColor={'red.200'}>Already joined free Raffle</Text>} */}
              </VStack>
            }

            {address && !isCancelled && canCancel && <Button
              colorScheme={'red'}
              variant={'ghost'}
              onClick={() => {
                if (!id) return
                web3Service.cancelRaffle(Number(id))
              }}
            >
              Cancel
            </Button>}

            {canClaimReward && <Button
              onClick={() => {
                if (!id) return
                web3Service.claimReward(Number(id))
              }}
            >
              Claim MATIC
            </Button>}

            {canClaimRefund && <Button
              onClick={() => {
                if (!id) return
                web3Service.claimRefund(Number(id))
              }}
            >
              Claim Refund
            </Button>}

            {canClaimPrize && <Button
              onClick={() => {
                if (!id) return
                web3Service.claimNFT(Number(id))
              }}
            >
              Claim NFT
            </Button>}
          </HStack>
        </Center>

        <Alert mt={4}
          bgColor={'rgba(0, 0, 0, 0.2)'}
          backdropFilter="auto"
          backdropBlur="8px"
          justifyContent='start'
          textAlign='left'
          status='info'>
          <List spacing={2} w={'100%'}>
            {/* <ListItem><Flex>Status<Spacer /><Code colorScheme={statusColor}>{raffleStatus}</Code></Flex></ListItem> */}
            <ListItem><Flex>Created by<Spacer /><Code colorScheme='black'>{`${raffle.creator.slice(0, 6)}...${raffle.creator.slice(-4)}`}</Code></Flex></ListItem>
            <ListItem><Flex>Ticket Price<Spacer /><Code colorScheme='black'>{ethers.utils.formatEther(raffle.ticketPrice)} MATIC</Code></Flex></ListItem>
            <ListItem><Flex>Tickets<Spacer /><Code colorScheme='black'>{players.length}/{Number(raffle.maxPlayers)}</Code></Flex></ListItem>
            <ListItem><Flex>Your Tickets<Spacer /><Code colorScheme='black'>{address ? (orderedPlayers.get(address.toLowerCase()) ?? 0) : 0}</Code></Flex></ListItem>
            {raffle.players.length > 0 && !isCancelled && <ListItem><Flex>Win Chance<Spacer /><Code colorScheme='black'>{`${(address ? (orderedPlayers.get(address.toLowerCase()) ?? 0) : 0) / Number(raffle.players.length) * 100}%`}</Code></Flex></ListItem>}
            {endDate && <ListItem><Flex>End Date<Spacer /><Code colorScheme='black'>{endDate.toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</Code></Flex></ListItem>}
            {BigNumber.from(raffle.winner).isZero() && <ListItem><Flex>Countdown<Spacer /><Code colorScheme='black'>{(new Date() > new Date(Number(raffle.timestamp) * 1000)) ? `Expired` : <Countdown date={Number(raffle.timestamp) * 1000} renderer={renderer} />}</Code></Flex></ListItem>}
          </List>
        </Alert>

        {(!isCancelled || players.length > 0) &&
          <Alert mt={8}
            bgColor={'rgba(0, 0, 0, 0.2)'}
            justifyContent='start'
            textAlign='left'
            status='info'>
            <AlertDescription w={'100%'}>
              <AlertTitle textAlign={'center'}>Tickets</AlertTitle>
              <OrderedList>
                {
                  players.length > 0
                    ?
                    Array.from(orderedPlayers.entries()).map((item, index) =>
                      <ListItem key={index} py={1}><Flex><Code>{item[0].slice(0, 6)}...{item[0].slice(-4)}</Code><Spacer /><Code colorScheme='black'>{item[1]}</Code></Flex></ListItem>
                    )
                    :
                    <Text>No players yet</Text>
                }
              </OrderedList>
            </AlertDescription>
          </Alert>}

      </Container>
    </VStack>}
  </>

  return (
    <>
      {isLoading ? loadingContent : (raffle && !BigNumber.from(raffle.creator).isZero() ? content : noExistsContent)}
    </>
  );
}

export default RaffleItemView;
