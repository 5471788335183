import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { merge, tap } from "rxjs";
import Web3Service, { StakeInfo, StakingInfo } from "../core/web3.service";
import Countdown from 'react-countdown';
import NFTStakeView from "./NFTStakeView";
import { HOS_PETS_ADDRESS } from "../config-hos-pets";
import { STAKING_ABI, STAKING_ADDRESS } from "../config-staking";

const HosPetsStakingView: React.FC = () => {
  const web3Service = Web3Service.shared();

  const [account, setAccount] = useState<string | undefined>(undefined);

  const [tokens, setTokens] = useState<number[]>([]);
  const [boosters, setBoosters] = useState<number[]>([]);

  const [selectedTokens, setSelectedTokens] = useState<number[]>([]);
  const [selectedStakedTokens, setSelectedStakedTokens] = useState<number[]>([]);

  const [selectedBoosters, setSelectedBoosters] = useState<number[]>([]);
  const [selectedStakedBoosters, setSelectedStakedBoosters] = useState<number[]>([]);

  const [rewards, setRewards] = useState<string | undefined>(undefined)
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [isBoosterApproved, setIsBoosterApproved] = useState<boolean>(false)
  const [stakingInfo, setStakingInfo] = useState<StakingInfo | undefined>(undefined)
  const [stakeInfo, setStakeInfo] = useState<StakeInfo | undefined>(undefined);

  useEffect(() => {
    web3Service.getUserHosPets(undefined);
    // web3Service.getUserBoosters(undefined);
    web3Service.getStakeInfo(STAKING_ADDRESS, STAKING_ABI)
    web3Service.getStakingInfo(STAKING_ADDRESS, STAKING_ABI)
    web3Service.isApprovedForAll(HOS_PETS_ADDRESS, STAKING_ADDRESS)
    // web3Service.isBoosterApproved()
    web3Service.getRewards(STAKING_ADDRESS, STAKING_ABI)

    const account$ = web3Service.account$.pipe(
      tap((account) => {
        setAccount(account);
      })
    );

    const isApproved$ = web3Service.isApprovedForAll$.pipe(
      tap((isApproved) => {
        setIsApproved(isApproved);
      })
    );

    // const isBoosterApproved$ = web3Service.isBoosterApproved$.pipe(
    //   tap((isApproved) => {
    //     setIsBoosterApproved(isApproved);
    //   })
    // );

    const rewards$ = web3Service.rewards$.pipe(
      tap((rewards) => {
        setRewards(rewards);
      })
    );

    const tokens$ = web3Service.hosPets$.pipe(
      tap((tokens) => {
        setTokens(tokens);
        setSelectedStakedTokens([])
        setSelectedTokens([])
      })
    );

    // const boosters$ = web3Service.boosters$.pipe(
    //   tap((tokens) => {
    //     setBoosters(tokens);
    //     setSelectedBoosters([])
    //     setSelectedStakedBoosters([])
    //   })
    // );

    const stakingInfo$ = web3Service.stakingInfo$.pipe(
      tap((stakingInfo) => {
        setStakingInfo(stakingInfo);
      })
    );

    const stakeInfo$ = web3Service.stakeInfo$.pipe(
      tap((stakeInfo) => {
        setStakeInfo(stakeInfo);
        setSelectedStakedTokens([])
        setSelectedTokens([])
      })
    );

    const subscription = merge(
      rewards$,
      isApproved$,
      // isBoosterApproved$,
      tokens$,
      // boosters$,
      stakingInfo$,
      stakeInfo$,
      account$
    ).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [account]);

  const renderer = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return undefined
    } else {
      return <span>{days}d {hours}h {minutes}m</span>;
    }
  }

  const mappedStaked = stakeInfo?.tokenIds.map(token => Number(token)) ?? []
  const mappedAll = tokens.map(token => Number(token))
  const unstakedTokens = mappedAll.filter(token => !mappedStaked.some(t => t == token))

  const mappedStakedBoosters = stakeInfo?.boosterIds.map(token => Number(token)) ?? []
  const mappedAllBoosters = boosters.map(token => Number(token))
  const unstakedBoosters = mappedAllBoosters.filter(token => !mappedStakedBoosters.some(t => t == token))

  let tabs = <>
    <Tabs colorScheme='white'>
      <Center>
        <TabList>
          <Tab>Available</Tab>
          <Tab>Staked</Tab>
        </TabList>
      </Center>
      <TabPanels>
        <TabPanel>
          <>
            <Heading textAlign={'center'}>Pets</Heading>
            <Center mt={4}>
              <Button
                onClick={() => isApproved ? web3Service.stake(STAKING_ADDRESS, STAKING_ABI, selectedTokens) : web3Service.approveForAll(HOS_PETS_ADDRESS, STAKING_ADDRESS)}
                isDisabled={selectedTokens.length == 0 && isApproved}
                variant={"solid"}
                colorScheme={"brand"}
              >
                {isApproved ? ('Stake' + (selectedTokens.length > 1 ? ` ${selectedTokens.length}` : '')) : `Approve to Stake`}
              </Button>
            </Center>

            <SimpleGrid
              columns={{ base: 2, md: 4 }}
              spacing={{ base: 4, md: 8 }}
              my={{ base: 4, md: 8 }}
              mx={"auto"}
              maxW={"2xl"}
            >
              {
                unstakedTokens.map((token, i) => (
                  <VStack key={i} spacing={2}
                    onClick={() => {
                      const tokenId = Number(token)
                      var copy = selectedTokens
                      if (selectedTokens.some(e => e == tokenId)) {
                        const index = copy.indexOf(token, 0);
                        if (index > -1) {
                          copy.splice(index, 1);
                        }
                      } else if (copy.length < 50) {
                        copy.push(token)
                      }
                      setSelectedTokens([...copy])
                    }}
                  >
                    <NFTStakeView
                      name={`#${Number(token)}`}
                      image={`https://bafybeighlosarlqbi3ka76ynef5cffu5vkmj4pflyw4mb7afur7houapmi.ipfs.dweb.link/${Number(token)}.png`}
                      color={selectedTokens.some(d => d == Number(token)) ? 'red' : 'white'}
                      isStaked={false}
                      isBoosted={false}
                    />
                  </VStack>
                ))
              }
            </SimpleGrid>

            {/* <Heading mt={8} textAlign={'center'}>Boosters</Heading>
            <Text textAlign={'center'}>Boosters</Text>
            <Center mt={4}>
              <Button
                onClick={() => isBoosterApproved ? web3Service.stakeBooster(selectedBoosters) : web3Service.approveBoostersForAll()}
                isDisabled={selectedBoosters.length == 0 && isBoosterApproved}
                variant={"solid"}
                colorScheme={"brand"}
              >
                {isBoosterApproved ? ('Boost' + (selectedBoosters.length > 1 ? ` ${selectedBoosters.length}` : '')) : `Approve to Boost`}
              </Button>
            </Center>

            <SimpleGrid
              columns={{ base: 2, md: 4 }}
              spacing={{ base: 4, md: 8 }}
              my={{ base: 4, md: 8 }}
              mx={"auto"}
              maxW={"2xl"}
            >
              {unstakedBoosters.map((token, i) => (
                <VStack key={i} spacing={2}
                  onClick={() => {
                    const tokenId = Number(token)
                    var copy = selectedBoosters
                    if (selectedBoosters.some(e => e == tokenId)) {
                      const index = copy.indexOf(token, 0);
                      if (index > -1) {
                        copy.splice(index, 1);
                      }
                    } else if (copy.length < 20) {
                      copy.push(token)
                    }
                    setSelectedBoosters([...copy])
                  }}
                >
                  <NFTItemView
                    name={`#${Number(token)}`}
                    image={`https://bafybeictvca7bcg2q6lk4qua73t2reiijw2vqjeloxa2sdqeian5sqagjy.ipfs.nftstorage.link/${Number(token)}.png`}
                    color={selectedBoosters.some(d => d == Number(token)) ? 'red' : 'white'}
                    isStaked={false}
                  />
                </VStack>
              ))}
            </SimpleGrid> */}
          </>
        </TabPanel>
        <TabPanel>
          <>
            <Heading textAlign={'center'}>Pets</Heading>
            <Center mt={4}>
              <Button
                onClick={() => web3Service.unstake(STAKING_ADDRESS, STAKING_ABI, selectedStakedTokens)}
                isDisabled={selectedStakedTokens.length == 0}
                variant={"solid"}
                colorScheme={"brand"}
              >
                {'Unstake' + (selectedStakedTokens.length > 1 ? ` ${selectedStakedTokens.length}` : '')}
              </Button>
            </Center>

            <SimpleGrid
              columns={{ base: 2, md: 4 }}
              spacing={{ base: 4, md: 8 }}
              my={{ base: 4, md: 8 }}
              mx={"auto"}
              maxW={"2xl"}
            >
              {mappedStaked.map((token, i) => (
                <VStack key={i} spacing={2}
                  onClick={() => {
                    const tokenId = Number(token)
                    var copy = selectedStakedTokens
                    if (selectedStakedTokens.some(e => e == tokenId)) {
                      const index = copy.indexOf(token, 0);
                      if (index > -1) {
                        copy.splice(index, 1);
                      }
                    } else if (copy.length < 50) {
                      copy.push(token)
                    }
                    setSelectedStakedTokens([...copy])
                  }}
                >
                  <NFTStakeView
                    name={`#${Number(token)}`}
                    image={`https://bafybeighlosarlqbi3ka76ynef5cffu5vkmj4pflyw4mb7afur7houapmi.ipfs.dweb.link/${Number(token)}.png`}
                    color={selectedStakedTokens.some(d => d == Number(token)) ? 'red' : 'white'}
                    isStaked={true}
                    isBoosted={false}
                  />
                </VStack>
              ))}
            </SimpleGrid>

            {/* <Heading mt={8} textAlign={'center'}>Boosters</Heading>
            <Text textAlign={'center'}>Boosters</Text>
            <Center mt={4}>
              <Button
                onClick={() => web3Service.unstakeBooster(selectedStakedBoosters)}
                isDisabled={selectedStakedBoosters.length == 0}
                variant={"solid"}
                colorScheme={"brand"}
              >
                {'Unstake' + (selectedStakedBoosters.length > 1 ? ` ${selectedStakedBoosters.length}` : '')}
              </Button>
            </Center>

            <SimpleGrid
              columns={{ base: 2, md: 4 }}
              spacing={{ base: 4, md: 8 }}
              my={{ base: 4, md: 8 }}
              mx={"auto"}
              maxW={"2xl"}
            >
              {
                mappedStakedBoosters.map((token, i) => (
                  <VStack key={i} spacing={2}
                    onClick={() => {
                      const tokenId = Number(token)
                      var copy = selectedStakedBoosters
                      if (selectedStakedBoosters.some(e => e == tokenId)) {
                        const index = copy.indexOf(token, 0);
                        if (index > -1) {
                          copy.splice(index, 1);
                        }
                      } else if (copy.length < 20) {
                        copy.push(token)
                      }
                      setSelectedStakedBoosters([...copy])
                    }}
                  >
                    <NFTItemView
                      name={`#${Number(token)}`}
                      image={`https://bafybeictvca7bcg2q6lk4qua73t2reiijw2vqjeloxa2sdqeian5sqagjy.ipfs.nftstorage.link/${Number(token)}.png`}
                      color={selectedStakedBoosters.some(d => d == Number(token)) ? 'red' : 'white'}
                      isStaked={true}
                    />
                  </VStack>
                ))
              }
            </SimpleGrid> */}
          </>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </>

  return (
    <>
      <Box
        mt={8}
        px={4}
      >
        <Box py={4}>
          {stakingInfo ?
            <SimpleGrid
              textColor={'rgb(255, 255, 255)'}
              columns={{ base: 1, md: 2 }}
              spacing={{ base: 4, md: 8 }}
              my={{ base: 4, md: 8 }}
              mx={"auto"}
              maxW={"3xl"}
            >
              <Stat
                bg={"rgba(0, 0, 0, 0.25)"}
                border={'2px solid white'}
                rounded={"xl"}
                boxShadow={"md"}
                py={4}
                px={8}>
                <StatNumber fontSize={'md'}>{mappedStaked.length} staked</StatNumber>
                <StatLabel>Available to claim</StatLabel>
                <StatNumber>{rewards ?? '-'} WETH</StatNumber>
                <Center mt={4}>
                  <Button
                    onClick={() => web3Service.claimRewards(STAKING_ADDRESS, STAKING_ABI)}
                    variant={"solid"}
                    colorScheme={"brand"}
                  >
                    Claim
                  </Button>
                </Center>
              </Stat>

              <Stat
                bg={"rgba(0, 0, 0, 0.25)"}
                border={'2px solid white'}
                rounded={"xl"}
                boxShadow={"md"}
                py={4}
                px={8}>
                <StatLabel>Total Staked</StatLabel>
                <StatNumber>{Number(stakingInfo.totalStaked)}</StatNumber>
                <StatLabel>Pool</StatLabel>
                <StatNumber>{Number(ethers.utils.formatEther(stakingInfo.pool)).toFixed(4)} WETH</StatNumber>
                <StatLabel>Time Left</StatLabel>
                {
                  Number(stakingInfo.seasonEndTimestamp) != 0
                    ?
                    <StatNumber>{(new Date() > new Date(Number(stakingInfo.seasonEndTimestamp) * 1000)) ? `Ended` : <Countdown date={Number(stakingInfo.seasonEndTimestamp) * 1000} renderer={renderer} />}</StatNumber>
                    :
                    <StatNumber>-</StatNumber>

                }
              </Stat>
            </SimpleGrid>
            :
            <Center><Heading>Loading...</Heading></Center>
          }
        </Box>

        {tabs}
      </Box>
    </>
  );
};

export default HosPetsStakingView;
